import React, { useState,useEffect } from 'react'
import '../../css/confirmModal.css'

const ConfirmModal = ({ code, registerUser, setNewPassword}) => {
    const [codeInput, setCodeInput] = useState(new Array(6).fill(''));
    const [codeResponse,setCodeResponse] = useState('')
    
    useEffect(() => {
        if (code) {
            setCodeResponse(code.message)
        }
    }, [code])
    
    const confirm = () => {
        const inputCode = codeInput.join('');
        
        setCodeInput(new Array(6).fill(''));

        const jwtDecode = (token) => {
            try {
              const base64Url = token.split('.')[1];
              const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
              const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));
          
              return JSON.parse(jsonPayload);
            } catch (error) {
              throw new Error('Invalid token specified');
            }
          };
        const jwtcode = jwtDecode(code.jtdcd)
        if (inputCode === jwtcode.customerData.codePass) {
            setNewPassword(true)
        } else {
            setCodeResponse("Wrong Code Input")
        }
    };

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) {
            const newCode = [...codeInput];
            newCode[index] = value;
            setCodeInput(newCode);

            // Focus the next input field if a digit is entered
            if (value && index < 5) {
                document.getElementById(`code-${index + 1}`).focus();
            }
        }
    };

    return (
        <div className="confirm-code-modal">
            <div className="ccmdl-main">
                <div className="ccmdl-head">
                    <h1>Confirm Email Code</h1>
                    <p>{codeResponse}</p>
                </div>
                <div className="ccmdl-content">
                    {codeInput.map((digit, index) => (
                        <input
                            key={index}
                            id={`code-${index}`}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            className="code-input"
                        />
                    ))}
                </div>
                <div className="cmdl-cnt-btn">
                    <button onClick={confirm}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
