import React from 'react'
// icons
import { MdOutlineAssignment } from "react-icons/md";

const ParticipantAggreement = ({userAgree,setUserAgree}) => {

    const agreeTerms = () => {
        setUserAgree(userAgree => !userAgree)
    }
  return (
    <div className="prtcpnt-agrmt">
        <h2><MdOutlineAssignment id='rgSecIcon'/> Participant Agreement</h2>
        <p>This Participant Agreement ("Skinmiso Partnership") is made between Skinmiso Canada and the undersigned participant ("Participant"). By signing this Agreement, Participant agrees to the following terms and conditions:</p>
        <ul>
            <li>
                <h3>1. Program Purpose</h3>
                <p>The purpose of this program is to provide a platform for participants to gain exposure, engage with a community of supporters, and earn rewards through a unique purchase-based system. This Agreement outlines the expectations, responsibilities, and rewards structure for participants.</p>
            </li>
            <li>
                <h3>2. Participant Responsibilities</h3>
                <h4>2.1 Active Social Media Presence</h4>
                <p>Participant agrees to maintain an active presence on social media platforms. This includes:</p>
                <p>• Providing valid and active links to at least two social media profiles.</p>
                <p>• Posting regular updates on progress and goals as part of the program.</p>
                <p>• Interacting with supporters and responding to comments/questions in a professional, respectful manner.</p>
                <h4>2.2 Unique Code Usage</h4>
                <p>Participant will be issued a unique code ("Code") that supporters can use during purchases. By sharing this Code, Participant enables supporters to allocate a portion of their purchase towards rewards that will benefit Participant. Participant agrees to:</p>
                <p>• Promote the Code on social media and other channels.</p>
                <p>• Encourage supporters to use the Code to maximize their potential rewards.</p>
                <h4>2.3 Engagement with Supporters</h4>
                <p>• Expressing gratitude for support received.</p>
                <p>• Sharing updates on progress, achievements, and milestones.</p>
                <p>• Engaging respectfully and professionally with all members of the community.</p>
            </li>
            <li>
                <h3>3. Rewards Program</h3>
                <h4>3.1 Monthly Rewards Distribution</h4>
                <p>Participant acknowledges and agrees to the rewards structure, wherein a portion of each purchase made using the Code will be converted into reward points. These points will be accumulated and distributed to Participant on a monthly basis. The reward distribution process includes:</p>
                <p>• Monthly calculation of points based on supporter purchases.</p>
                <p>• Direct deposit or credit of rewards to Participant’s designated account.</p>
                <h4>3.2 Reward Withdrawal Conditions</h4>
                <p>Rewards earned are subject to the following conditions:</p>
                <p>• Participants must have active status within the program.</p>
                <p>• Participants must maintain adherence to this Agreement, including program guidelines and social media engagement.</p>
            </li>
            <li>
                <h3>4. Promotion and Publicity</h3>
                <h4>4.1 Consent to Publicity</h4>
                <p>Participant agrees to allow Skinmiso Canada to promote Participant’s profile, progress, and achievements on the program website and social media channels. Publicity efforts may include:</p>
                <p>• Sharing Participant’s profile picture, bio, and social media links.</p>
                <p>• Featuring Participant in promotional materials, updates, and program announcements.</p>
                <h4>4.2 Use of Content</h4>
                <p>Participant grants Skinmiso Canada a non-exclusive, royalty-free license to use Participant’s image, social media links, and profile information solely for promotional purposes related to the program.</p>
            </li>
            <li>
                <h3>5. Code of Conduct</h3>
                <p>Participant agrees to:</p>
                <p>• Exhibit respect and professionalism when interacting with all supporters, other participants, and program administrators.</p>
                <p>• Refrain from any form of harassment, inappropriate behavior, or language that could harm the reputation of the program.</p>
                <p>• Act in a way that promotes a positive, supportive community environment.</p>
            </li>
            <li>
                <h3>6. Termination of Agreement</h3>
                <h4>Skinmiso Canada reserves the right to terminate this Agreement at any time, with or without cause. Grounds for termination include, but are not limited to:</h4>
                <p>• Violation of the terms outlined in this Agreement.</p>
                <p>• Inactive social media presence or failure to engage with the program as agreed.</p>
                <p>• Conduct that damages the reputation of the program or Skinmiso Canada.</p>
            </li>
            <li>
                <h3>7. Liability Waiver</h3>
                <p>Participant agrees to hold Skinmiso Canada harmless from any claims, damages, losses, or expenses arising from Participant’s participation in the program or from any third-party actions related to the use of the Code.</p>
            </li>
            <li>
                <h3>8. Entire Agreement</h3>
                <p>This Agreement constitutes the entire understanding between Participant and Skinmiso Canada with respect to the program and supersedes any prior agreements or understandings. No modifications shall be valid unless in writing and signed by both parties.</p>
            </li>
            <hr />
            <span>By Proceeding, Participant acknowledges and agrees to the terms and conditions of this Agreement.</span>
            <br /><br />
            <span>This agreement ensures that all participants understand their commitments, responsibilities, and rewards, while protecting the interests of the company</span>
            <div className="partcipant-agreement-btn">
                <input 
                    type="checkbox" 
                    checked={userAgree}
                    onChange={agreeTerms}
                /> <h2>I, the "Participant", read and agreed to the terms and conditions of the company "Skinmiso Canada."</h2>
            </div>
        </ul>
        <span>Note: The user needs to scroll down at the very end to proceed to this form.</span>
    </div>
  )
}

export default ParticipantAggreement