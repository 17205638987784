import React, { useState } from 'react';
// css
import '../../css/editUser-modal.css';
// cookies
import Cookies from 'js-cookie';
// axios
import axios from 'axios';
// icons
import { RiImageEditLine } from "react-icons/ri";

const Edituser = ({ userInfo = [], setEditData, apiBaseURL, setCreateSuccess, setSuccessContent, defaultProfile }) => {
  const [editableData, setEditableData] = useState(Array.isArray(userInfo) ? userInfo : [userInfo]);
  const [profilePic, setProfilePic] = useState(defaultProfile);
  const [profileFile,setImgfile] = useState(null)

  // Function to handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create a URL for the selected file
      setProfilePic(URL.createObjectURL(file));
      setImgfile(file)
    }
  };

  const handleChange = (index, field, value) => {
    const updatedData = [...editableData];
    updatedData[index][field] = value;
    setEditableData(updatedData);
  };

  const updataUser = () => {
    const authToken = Cookies.get('authToken');
    const userData = editableData[0];
    console.log(userData);
    
    
    const imgData = new FormData();
    imgData.append("profilePic", profileFile)
    imgData.append("customerID", userInfo[0].user_customerID)

    axios
      .post(`${apiBaseURL}/update-user-data`, { userData }, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
      })
      .then((response) => {
        console.log(response);
        
        if (response.data.success === true ) {
          if (profileFile === null) {
            setEditData(false);
            setSuccessContent("Successfully Edited Personal Info");
            setCreateSuccess(true);
          } else {
            try {
              axios
                .post(`${apiBaseURL}/upload-profile-picture`, imgData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${authToken}`
                  },
                })
                .then((response) => {
                  if (response.data.success === true) {
                    setEditData(false);
                    setSuccessContent("Successfully Edited Personal Info");
                    setCreateSuccess(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  
                })
              } catch (error) {
                console.log(error);
              }
            }
          }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  return (
    <div className="edit-user-info">
      <div className="dtsrnf">
        <div className="dtsrnf-container">
          <div className="dtsrnf-header">
            <h1>User Information</h1>
            <hr />
          </div>
          {editableData.length > 0 && (
            <>
              {editableData.map((item, index) => (
                <div className="dtsrnf-contents" key={index}>
                  <div className="editProfData">
                    <span>
                    {profileFile ? 
                      <img src={profilePic} alt="" /> :
                      <img src={item.user_profile_pic ? `https://2wave.io/skinmiso/customer_profile_picture/${item.user_profile_pic}` : profilePic} alt="" />
                    }
                    </span>
                    <div className="chng-prf">
                      <label htmlFor="profilePicInput" className="icon-label">
                        <RiImageEditLine />
                      </label>
                      <input 
                        type="file" 
                        accept="image/*" 
                        onChange={handleFileChange} 
                        style={{ display: 'none' }} 
                        id="profilePicInput" 
                      />
                    </div>
                  </div>
                  <section>
                    <ul>
                      <li>
                        <p>First Name:</p>
                        <input
                          type="text"
                          placeholder={item.user_first_name ? '' : "Skinmiso"}
                          value={item.user_first_name || ''}
                          onChange={(e) => handleChange(index, 'user_first_name', e.target.value)}
                        />
                      </li>
                      <li>
                        <p>Last Name:</p>
                        <input
                          type="text"
                          placeholder={item.user_last_name ? '' : "Canada"}
                          value={item.user_last_name || ''}
                          onChange={(e) => handleChange(index, 'user_last_name', e.target.value)}
                        />
                      </li>
                      <li>
                        <p>Gender:</p>
                        <select
                          value={item.user_gender || ''}
                          onChange={(e) => handleChange(index, 'user_gender', e.target.value)}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="LGBTQIA+">LGBTQIA+</option>
                        </select>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <p>Email:</p>
                        <input
                          type="text"
                          placeholder={item.user_email ? '' : "Canada"}
                          value={item.user_email || ''}
                          onChange={(e) => handleChange(index, 'user_email', e.target.value)}
                        />
                      </li>
                      <li>
                        <p>Mobile Number:</p>
                        <input
                          type="text"
                          placeholder={item.user_mobileno ? '' : "+123"}
                          value={item.user_mobileno || ''}
                          onChange={(e) => handleChange(index, 'user_mobileno', e.target.value)}
                        />
                      </li>
                      <li>
                        <p>Birthday:</p>
                        <input
                          type="date"
                          value={item.user_birthday || ''}
                          onChange={(e) => handleChange(index, 'user_birthday', e.target.value)}
                        />
                      </li>
                    </ul>
                  </section>
                </div>
              ))}
            </>
          )}
          <div className="svEdit-changes">
            <button onClick={updataUser}>Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edituser;
