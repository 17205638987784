import React from 'react'
// css
import '../css/vitaminCline.css'
// icons
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaStar, FaStarHalf } from "react-icons/fa";
// assets
import vtmcSerum from '../assets/imgs/vcL-vtmCSerum.png'
import vtmcToner from '../assets/imgs/vcL-vtmCToner.png'
import vtmcCream from '../assets/imgs/vcL-vtmCcream.png'

import sampleprod1 from '../assets/imgs/Pore Purifying Toner.png'
import sampleprod2 from '../assets/imgs/Pore Zero Night Cream.png'
import sampleprod3 from '../assets/imgs/Pure Vitamin C Serum.png'

import organic from '../assets/imgs/Organic.png'
import cruelty from '../assets/imgs/CrueltyFree.png'
import paraben from '../assets/imgs/Paraban Free.png'

import howto from '../assets/imgs/howtovit.png'
import pcmain from '../assets/imgs/pcmain.jpg'
import pcimg1 from '../assets/imgs/pcmain (2).jpg'
import pcimg2 from '../assets/imgs/pcmain (3).jpg'
import pcimg3 from '../assets/imgs/pcmain (4).jpg'
import pcimg4 from '../assets/imgs/pcmain (5).jpg'
import pcimg5 from '../assets/imgs/pcmain (6).jpg'
import pcimg6 from '../assets/imgs/pcmain (7).jpg'

import vtcCreamperson from '../assets/imgs/vitcCream.jpg'
import vtcCSerumperson from '../assets/imgs/vitCSerum.jpg'
import vitcTonerperson from '../assets/imgs/vitCToner.jpg'
import vitcCream from '../assets/imgs/vitaminCCream.jpg'
import vitcSerum from '../assets/imgs/vitaminCSerum.jpg'
import vitcToner from '../assets/imgs/vitaminCToner.jpg'


const VitaminC = () => {
  return (
    <div className="vitaminCline">
        <div className="vitaminCLine-main">
            <div className="vtmcl-container">
                <div className="vtmcl-header">
                    <h1>Vitamin C</h1>
                    <hr />
                    <p>Your Ultimate Vitamin C Trio</p>
                </div>
                <div className="vtmcl-content">
                    <section>
                        <div className="vitaminC-Serum-content">
                            <div className="vtmCS-header">
                                <div className="vtmCS-img">
                                    <img src={vtmcSerum} alt="" />
                                </div>
                                <div className="vtmCsHtxt">
                                    <h1>Skinmiso pure Vitamin C Brightening Serum</h1>
                                    <p>Enhance skin brightness and firmness while effectively removing thickened dead skin cells and providing ample moisture.</p>
                                </div>
                            </div>
                            <div className="vtmCS-contents">
                                <div className="vtmCsCtxt">
                                    <h1>Vitamin C Serum</h1>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Suitable for All Skin Types</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Brightens Skin Tone</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Boosts Collagen Production</p>
                                </div>
                                <ul>
                                    <li>
                                        <img src={vitcSerum} alt="" />
                                    </li>
                                    <li>    
                                        <img src={vtcCSerumperson} alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="vitaminC-Serum-content">
                            <div className="vtmCS-header">
                                <div className="vtmCS-img">
                                    <img src={vtmcToner} alt="" />
                                </div>
                                <div className="vtmCsHtxt">
                                    <h1>Skinmiso Pure Vitamin C Brightening Toner</h1>
                                    <p>Smoothens rough skin and brightens the complexion and provide essential moisture.</p>
                                </div>
                            </div>
                            <div className="vtmCS-contents">
                                <div className="vtmCsCtxt">
                                    <h1>Vitamin C Toner</h1>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Brightens & Refreshes</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Balances Skin pH</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Fights Free Radicals</p>
                                </div>
                                <ul>
                                    <li>
                                        <img src={vitcToner} alt="" />
                                    </li>
                                    <li>    
                                        <img src={vitcTonerperson} alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="vitaminC-Serum-content">
                            <div className="vtmCS-header">
                                <div className="vtmCS-img">
                                    <img src={vtmcCream} alt="" />
                                </div>
                                <div className="vtmCsHtxt">
                                    <h1>Skinmiso Pure Vitamin C Brightening Cream</h1>
                                    <p>Nourish, brightens, tightens, and shields for youthful, radiant skin.</p>
                                </div>
                            </div>
                            <div className="vtmCS-contents">
                                <div className="vtmCsCtxt">
                                    <h1> Vitamin C Cream</h1>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Non-Irritating Formula</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Gentle Exfoliation</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/>Fights Free Radicals</p>
                                </div>
                                <ul>
                                    <li>
                                        <img src={vitcCream} alt="" />
                                    </li>
                                    <li>    
                                        <img src={vtcCreamperson} alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="pc-prdPromise">
                            <h1>Our Product Promise</h1>
                            <ul>
                                <li>
                                    <img src={organic} alt="" />
                                    <p>100% Vegan</p>
                                </li>
                                <li>
                                    <img src={cruelty} alt="" />
                                    <p>Cruelty Free</p>
                                </li>
                                <li>
                                    <img src={paraben} alt="" />
                                    <p>Paraben Free</p>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <div className="hwtuse">
                            <img src={howto} alt="" />
                        </div>
                    </section>
                    <section>
                        <div className="prcrPerfect">
                            <h1>Vitamin C Radiance Stories</h1>
                            <div className="prcrPrf-content">
                                <ul>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                </ul>
                            </div>
                            <p>
                                Watch as our customers reveal their journey to glowing, healthy skin with the SKINMISO Pure Vitamin C Skincare. See the transformation unfold in real-time and discover how our products can help you achieve your skincare goals.
                            </p>
                        </div>
                    </section>
                    <section>
                        <div className="pcRlprd">
                            <h1>Related Products</h1>
                        </div>
                        <div className="pcRlprd-contents">
                            <ul>
                                <li>
                                    <span>Best</span>
                                    <img src={sampleprod1} alt="" />
                                    <div className="pcPrd-info">
                                        <h1>Pore Purifying Toner</h1>
                                        <p>
                                            <div className="pcPrdStar">
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStarHalf id='pcRprdstr'/>
                                            </div>
                                            (4.75)
                                        </p>
                                    </div>
                                    <h2>$17.00</h2>
                                    <h3>4,980 Reviews</h3>
                                </li>
                                <li>
                                    <span>Best</span>
                                    <img src={sampleprod2} alt="" />
                                    <div className="pcPrd-info">
                                        <h1>Pore Zero Night Cream</h1>
                                        <p>
                                            <div className="pcPrdStar">
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStarHalf id='pcRprdstr'/>
                                            </div>
                                            (4.75)
                                        </p>
                                    </div>
                                    <h2>$17.00</h2>
                                    <h3>4,980 Reviews</h3>
                                </li>
                                <li>
                                    <span>Best</span>
                                    <img src={sampleprod3} alt="" />
                                    <div className="pcPrd-info">
                                        <h1>Pure Vitamin C Serum</h1>
                                        <p>
                                            <div className="pcPrdStar">
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStarHalf id='pcRprdstr'/>
                                            </div>
                                            (4.75)
                                        </p>
                                    </div>
                                    <h2>$17.00</h2>
                                    <h3>4,980 Reviews</h3>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <div className="pcSocAcc">
                            <h1>Follow @Skinmiso</h1>
                            <p>Use Hashtag <span>#LoveYourskinMISO</span> for a chance to be featured</p>
                            <div className="pcSocBnnrs">
                                <div className="pcSocMain">
                                    <img src={pcmain} alt="" />
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            <img src={pcimg1} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg2} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg3} alt="" />
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <img src={pcimg4} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg5} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg6} alt="" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
  )
}

export default VitaminC
