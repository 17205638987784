import React, {useState,useEffect} from 'react'
// css
import '../css/campaign.css'
// icons
import { FaUser,FaHandHoldingHeart,FaGifts } from "react-icons/fa";
import { BiSolidCoupon } from "react-icons/bi";
// assets
import celebrate from '../assets/imgs/celebrate.png'
import samplePoster from '../assets/imgs/sampleShortBanne.png'
import lockCard from '../assets/imgs/cardMan.png'

import samplePosterCampaign from '../assets/imgs/samplePostercampaign.png'
import samplePosterCampaign1 from '../assets/imgs/Glow Banner 2.png'
import samplePosterCampaign2 from '../assets/imgs/Glow Banner 4.png'
import samplePosterCampaign3 from '../assets/imgs/Glow Banner 3.png'

import samplePosterMB from '../assets/imgs/Mobile 1.png'
import samplePosterMB2 from '../assets/imgs/Mobile 2.png'
import samplePosterMB3 from '../assets/imgs/Mobile 3.png'
import samplePosterMB4 from '../assets/imgs/Mobile 4.jpg'
import axios from 'axios';


const apiBaseURL = process.env.REACT_APP_API_BASE_URL
const CampaignPage = ({setOpenParticipantForm}) => {

    const [activeImg,setActiveImg] = useState("img1")
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust for mobile screen width

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        // Add event listener to update mobile state on resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Desktop and Mobile Images
    const desktopImages = [
        samplePosterCampaign,
        samplePosterCampaign1,
        samplePosterCampaign2,
        samplePosterCampaign3,
    ];

    const mobileImages = [
        samplePosterMB,
        samplePosterMB2,
        samplePosterMB3,
        samplePosterMB4,
    ];

    const imagesToShow = isMobile ? mobileImages : desktopImages;

    useEffect(() => {
        const interval = setInterval(() => {
            if (activeImg === "img1") {
                setActiveImg("img2");
            } else if (activeImg === "img2") {
                setActiveImg("img3");
            } else if (activeImg === "img3") {
                setActiveImg("img4");
            } else {
                setActiveImg("img1");
            }
        }, 5000);
    
        return () => clearInterval(interval);
    }, [activeImg]);
    
    
    const slide1 = () =>{
        setActiveImg("img1");
    }
    const slide2 = () =>{
        setActiveImg("img2");
    }
    const slide3 = () =>{
        setActiveImg("img3");
    } 
    const slide4 = () =>{
        setActiveImg("img4");
    }

    const openParticipant = () => {
        setOpenParticipantForm(true)
    }

    useEffect(() => {
        fetchApproved()
    
    }, [])
    
    const [users,setUsers] = useState()

    const fetchApproved = () => {
        const region = 'Canada'
        console.log(apiBaseURL);
        
        axios
            .post(`${apiBaseURL}/participant-list`, {region})
            .then((response) => {
                setUsers(response.data.users)
            })
            .catch((error) => {
                console.log(error);
            })
    }

  return (
    <div className="campaignPage">
        <div className="campaignContainer">
            <div className="campaignHeader">
                <div className="chHeaderSlider">
                    <div className="sldrPtion">
                        <span onClick={slide1} id={`sld1-${activeImg}`}></span>
                        <span onClick={slide2} id={`sld2-${activeImg}`}></span>
                        <span onClick={slide3} id={`sld3-${activeImg}`}></span>
                        <span onClick={slide4} id={`sld4-${activeImg}`}></span>
                    </div>
                    <ul>
                        <div className={`hdSlder-poters ${activeImg}`}>
                            {imagesToShow.map((image, index) => (
                            <li key={index}>
                                <img src={image} alt={`Poster ${index}`} />
                            </li>
                            ))}
                        </div>
                    </ul>
                </div>
                <div className="chTlOfThMnth">
                    <img src={samplePoster} alt="" />
                </div>
            </div>
            <div className="campaignContents">
                <div className="cmContent-Head">
                    <h1>Glow Journey Gallery</h1>
                </div>
                <div className="campaignCards">
                    {/* <ul>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                        <li>
                            <img src={lockCard} alt="" />
                        </li>
                    </ul> */}
                    {users &&(
                        <>
                            {users.map((user,index) => (
                                <ul>
                                    <li key={index}>
                                        <img src={`https://2wave.io/skinmiso/customer_facecards/${user.user_participant_card_img}`} alt="" />
                                    </li>
                                </ul>
                            ))}
                        </>
                    )}
                </div>
                <div className="cmpgnTuts">
                    <h1>How it works?</h1>
                    <ul>
                        <li><FaUser id='stpsIcon'/><p>Register</p></li>
                        <li><BiSolidCoupon id='stpsIcon'/><p>Receive Support Codes</p></li>
                        <li><FaHandHoldingHeart id='stpsIcon'/><p>Support & Recognition</p></li>
                        <li><FaGifts id='stpsIcon'/><p>Giveaways</p></li>
                    </ul>
                    <div className="cmpttsTxt">
                        <p>1. Aspiring participants must introduce themselves and provide links to their social media profiles. This ensures they have an active platform where they regularly communicate with their followers and share their progress.</p>
                        <p>2. Each registered participant will be assigned a unique code. Our customers can use these codes during their purchases to show support for their favorite participant. By entering the code, a portion of the purchase will be converted into reward points for the supported individual, helping them gain in a small financial boost on their journey</p>
                        <p>3. Through our website and social media, we will continuously promote the registered participants. Our goal is to introduce them to a broader audience, giving them the visibility they deserve. This additional exposure not only helps them build their personal brand but also provide them with a community of supporters.</p>
                        <p>4. Every month, participants will recieve a portion of the rewards collected through their supporters purchases. This system is designed to provide ongoing encouragement and financial support, giving them more strength and motivation to continue pursuing their dreams.</p>
                    </div>
                    <div className="rgBtn">
                        <button onClick={openParticipant}>Click to Register</button>
                    </div>
                    <div className="cmpender">
                        <h1>Are you the next aspiring talents?</h1>
                        <img src={celebrate} alt="" />
                        <p>Join our Glow Together campaign and let SKINMISO be your partner in success. This is your chance to glow, grow, and inspire others. Register now and be the next star in our SKINMISO Family</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CampaignPage
