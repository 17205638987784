import React, { useRef, useEffect } from 'react';
import { TweenMax, TimelineMax, Linear, Elastic, Expo } from 'gsap';
import '../../css/flower-loader.css'

const FlowerLoader = () => {
  const flowerRef = useRef(null);
  const leavesRef = useRef(null);
  const centerRef = useRef(null);

  useEffect(() => {
    const LEAF_SVG = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.7 51.8" style="enable-background:new 0 0 23.7 51.8;" xml:space="preserve"><path d="M11.8,0c0,0-26.6,24.1,0,51.8C38.5,24.1,11.8,0,11.8,0z"/></svg>`;
    const numberOfLeaves = 6;
    const rotation = 360 / numberOfLeaves;
    const path = [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 }
    ];
    const location = { x: path[0].x, y: path[0].y };
    const tn1 = TweenMax.to(location, numberOfLeaves, {
      bezier: { curviness: 1.5, values: path },
      ease: Linear.easeNone
    });

    // Add leaves to the flower
    for (let i = 0; i < numberOfLeaves; i++) {
      const leaf = document.createElement('div');
      leaf.className = 'flower__leaf';
      leaf.innerHTML = `<div class="flower__leaf-inner">${LEAF_SVG}</div>`;
      tn1.time(i);

      TweenMax.set(leaf, {
        x: location.x,
        y: location.y,
        rotation: (rotation * i) - 90
      });

      leavesRef.current.appendChild(leaf);
    }

    // Set up the animation
    const leaves = leavesRef.current.querySelectorAll('.flower__leaf-inner');
    const timeline = new TimelineMax({
      onComplete: () => timeline.restart(true)
    });

    timeline
      .add(
        TweenMax.fromTo(centerRef.current, 1, { scale: 0 }, {
          scale: 1,
          ease: Elastic.easeOut.config(1.1, 0.75)
        }), 0
      )
      .add(
        TweenMax.staggerTo(leaves, 1, { scale: 1, ease: Elastic.easeOut.config(1.1, 0.75) }, 0.2), 0.3
      )
      .add(
        TweenMax.to(leaves, 0.3, { scale: 1.25, ease: Elastic.easeOut.config(1.5, 1) })
      )
      .add(
        TweenMax.to(leavesRef.current, 1, {
          rotation: 360,
          ease: Expo.easeInOut
        }), 1.7
      )
      .add(
        TweenMax.to(leaves, 0.5, { scale: 0, ease: Elastic.easeInOut.config(1.1, 0.75) })
      )
      .add(
        TweenMax.to(centerRef.current, 0.5, {
          scale: 0,
          ease: Elastic.easeInOut.config(1.1, 0.75)
        }), '-=0.37'
      );

    // Clean up on unmount
    return () => timeline.kill();
  }, []);

  return (
    <div className="flower" ref={flowerRef}>
      <div className="flower__center" ref={centerRef}></div>
      <div className="flower__leaves" ref={leavesRef}></div>
    </div>
  );
};

export default FlowerLoader;
