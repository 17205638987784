import React, {useState} from 'react'
// css
import '../../css/cart-modal.css'
// icons
import { TbDotsVertical,TbShoppingCartFilled  } from "react-icons/tb";
// assets
import samplePrd1 from '../../assets/imgs/Pore Purifying Toner.png'
import samplePrd2 from '../../assets/imgs/Pore Zero Night Cream.png'


const CartModal = ({openCartModal}) => {
  const [cartprdSetting, setCartprdsetting ] = useState(false)


  const openCartSet = () => {
    setCartprdsetting(cartprdSetting => !cartprdSetting )
  }

  return (
    <div className={`cart-modal ${openCartModal}`}>
      <div className="cartModal-main">
        <div className="crtMdl-container">
          <div className="crtMdl-header">
            <h1>My Cart</h1>
            <TbShoppingCartFilled id='fCrtBtn'/>
            <span>view full cart</span>
          </div>
          <hr />
          <div className="crtMdl-content">
            <ul>
              <li>
                <TbDotsVertical id='crtmdlprdSttngs' onClick={openCartSet}/>
                <div className={`crtmdlstt-container ${cartprdSetting}`}>
                  <h2>Send a Ticket</h2>
                  <h2>Remove from Cart</h2>
                </div>
                <div className="crdmdl-quantt">
                  <span>+</span>
                  <p>1</p>
                  <span>-</span>
                </div>
                <div className="crdmdl-img">
                  <img src={samplePrd1} alt="" />
                </div>
                <div className="crdmdl-dets">
                  <section>
                    <h1>Pore Purifying Toner</h1>
                    <p>$10.00</p>
                  </section>
                </div>
              </li>
              <li>
                <TbDotsVertical id='crtmdlprdSttngs' onClick={openCartSet}/>
                <div className={`crtmdlstt-container ${cartprdSetting}`}>
                  <h2>Send a Ticket</h2>
                  <h2>Remove from Cart</h2>
                </div>
                <div className="crdmdl-quantt">
                  <span>+</span>
                  <p>1</p>
                  <span>-</span>
                </div>
                <div className="crdmdl-img">
                  <img src={samplePrd2} alt="" />
                </div>
                <div className="crdmdl-dets">
                  <section>
                    <h1>Pore Zero Night Cream</h1>
                    <p>$8.00</p>
                  </section>
                </div>
              </li>
              <li>
                <TbDotsVertical id='crtmdlprdSttngs' onClick={openCartSet}/>
                <div className={`crtmdlstt-container ${cartprdSetting}`}>
                  <h2>Send a Ticket</h2>
                  <h2>Remove from Cart</h2>
                </div>
                <div className="crdmdl-quantt">
                  <span>+</span>
                  <p>1</p>
                  <span>-</span>
                </div>
                <div className="crdmdl-img">
                  <img src={samplePrd2} alt="" />
                </div>
                <div className="crdmdl-dets">
                  <section>
                    <h1>Pore Zero Night Cream</h1>
                    <p>$8.00</p>
                  </section>
                </div>
              </li>
              <li>
                <TbDotsVertical id='crtmdlprdSttngs' onClick={openCartSet}/>
                <div className={`crtmdlstt-container ${cartprdSetting}`}>
                  <h2>Send a Ticket</h2>
                  <h2>Remove from Cart</h2>
                </div>
                <div className="crdmdl-quantt">
                  <span>+</span>
                  <p>1</p>
                  <span>-</span>
                </div>
                <div className="crdmdl-img">
                  <img src={samplePrd2} alt="" />
                </div>
                <div className="crdmdl-dets">
                  <section>
                    <h1>Pore Zero Night Cream</h1>
                    <p>$8.00</p>
                  </section>
                </div>
              </li>
              <li>
                <TbDotsVertical id='crtmdlprdSttngs' onClick={openCartSet}/>
                <div className={`crtmdlstt-container ${cartprdSetting}`}>
                  <h2>Send a Ticket</h2>
                  <h2>Remove from Cart</h2>
                </div>
                <div className="crdmdl-quantt">
                  <span>+</span>
                  <p>1</p>
                  <span>-</span>
                </div>
                <div className="crdmdl-img">
                  <img src={samplePrd2} alt="" />
                </div>
                <div className="crdmdl-dets">
                  <section>
                    <h1>Pore Zero Night Cream</h1>
                    <p>$8.00</p>
                  </section>
                </div>
              </li>
              <li>
                <TbDotsVertical id='crtmdlprdSttngs' onClick={openCartSet}/>
                <div className={`crtmdlstt-container ${cartprdSetting}`}>
                  <h2>Send a Ticket</h2>
                  <h2>Remove from Cart</h2>
                </div>
                <div className="crdmdl-quantt">
                  <span>+</span>
                  <p>1</p>
                  <span>-</span>
                </div>
                <div className="crdmdl-img">
                  <img src={samplePrd2} alt="" />
                </div>
                <div className="crdmdl-dets">
                  <section>
                    <h1>Pore Zero Night Cream</h1>
                    <p>$8.00</p>
                  </section>
                </div>
              </li>
              <li>
                <TbDotsVertical id='crtmdlprdSttngs' onClick={openCartSet}/>
                <div className={`crtmdlstt-container ${cartprdSetting}`}>
                  <h2>Send a Ticket</h2>
                  <h2>Remove from Cart</h2>
                </div>
                <div className="crdmdl-quantt">
                  <span>+</span>
                  <p>1</p>
                  <span>-</span>
                </div>
                <div className="crdmdl-img">
                  <img src={samplePrd2} alt="" />
                </div>
                <div className="crdmdl-dets">
                  <section>
                    <h1>Pore Zero Night Cream</h1>
                    <p>$8.00</p>
                  </section>
                </div>
              </li>
            </ul>
          </div>
          <div className="crtTtl-order">
            <p>Total Checkout</p>
            <h1>$100.00</h1>
          </div>
          <div className="crtMd-quick-chkout">
            <button>Quick Checkout</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartModal