import React from 'react'
// css
import  '../../css/successCreateAcc.css'
// assets
import scCreateGif from '../../assets/imgs/successCreate.png'

function SuccessCreateAcc({createSuccess,setCreateSuccess,successContent}) {

    const closeCreateSuccess = () => {
        setCreateSuccess(false)
        setTimeout(() => {
            window.location.reload()
        }, 400);
    }
    
    return (
        <div className={`successCreateAcc ${createSuccess}`}>
            <div className="sccssCrtAcc-main">
                <div className="sccCrtAcc-header">
                    <img src={scCreateGif} alt="" />
                </div>
                <div className="sccssCrtAcc-Contents">
                    <h1>Success</h1>
                    <p>{successContent}</p>
                </div>
                <div className="scessdn">
                    <button onClick={closeCreateSuccess}>Done</button>
                </div>
            </div>
        </div>
    )
}

export default SuccessCreateAcc
