import React from 'react'
// css
import '../../css/terms-modal.css'
// assets
import logo from '../../assets/imgs/SkinmisoPlainCyanLogo.png'
// json
import termsJson from '../terms/terms.json'

const TermsModal = ({setCheckTerms,setCheckPrivacy,setOpenTerms}) => {

    const acceptTerms = () => {
        setCheckTerms(true)
        setCheckPrivacy(true)
        setOpenTerms(false)
    }

  return (
    <div className="termsModal">
        <div className="termsModal-main">
            <div className="trmModal-cotainer">
                <div className="trmModal-Header">
                    <div className="tcModal-logo">
                        <img src={logo} alt="" />
                    </div>
                    <h1>Terms & Condition and User Privacy Policy</h1>
                </div>
                <hr />
                <div className="trmModal-Content">
                    <ul>
                        {termsJson.map((article, i) => (
                            <li key={i}>
                                <h2>Article {article.article} <span>( {article.title} )</span></h2>
                                <p>{article.content}</p>
                            </li>
                        ))}
                        <h3>By reading and understanding the Terms & Conditions and Privacy Policy, I hereby agree to their terms</h3>
                        <div className="accptTermsCondition">
                            <button onClick={acceptTerms}>Accept and Continue</button>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TermsModal
