import React, { useState, useEffect } from 'react'
// css
import './css/nav.css'
// cookies
import Cookies from 'js-cookie';
//  router
import { Link } from 'react-router-dom';
// axios
import axios from 'axios';
// icons
import { 
    FaBars,
    FaShoppingBag,
    FaHome,
    FaUser,
    FaQuestionCircle,
    FaUserAltSlash 
} from "react-icons/fa";
import { 
    FaEyeDropper,
    FaNewspaper 
} from "react-icons/fa6";
import { AiFillProduct } from "react-icons/ai";
import { MdShoppingCart,MdRemoveShoppingCart,MdAutoFixHigh } from "react-icons/md";
import { RiInkBottleLine,RiHandSanitizerLine } from "react-icons/ri";
import { GiGlowingHands,GiHeartDrop } from "react-icons/gi";
import { IoIosArrowForward,IoMdArrowDroprightCircle  } from "react-icons/io";
import { BsHearts,BsBrightnessAltHigh } from "react-icons/bs";
import { BiSolidShoppingBags } from "react-icons/bi";
import { IoSettings,IoLogInOutline,IoNewspaperOutline } from "react-icons/io5";
import { PiHeartbeat } from "react-icons/pi";
import { HiOutlineLogout } from "react-icons/hi";
// assets
import logoCyan from './assets/imgs/SkinmisoPlainCyanLogo.png'
import sampleImguser from './assets/imgs/user.jpg'
import defaultProfile from './assets/imgs/defaultProfile.png'
// js file
import LoginModal from './pages/modals/login-modal';
import CartModal from './pages/modals/cart-modal';
import FetchUser from './pages/datafetch/fetchUser';
import SuccessCreateAcc from './pages/handling/successCreate';
import FetchProducts from './pages/datafetch/fetchProduct';
// import errorUser from './assets/imgs/userError.png'
// import emptyCart from './assets/imgs/empty-cart.png'
import TermsModal from './pages/modals/terms-modal';
import RegisterParticipant from './pages/modals/register-modal';


const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const Nav = ({openParticipanForm,setOpenParticipantForm,setFlowerLoader}) => {

    const [isHovered, setIsHovered] = useState(false);
    const [sideBar,setSideBar] = useState(false)
    const [widen,setWiden] = useState(false)
    const [viewAllPrd,setViewallprod] = useState(false)
    const [currentUser,setCurrentUser] = useState(null)
    const [openLogModal,setopenLogModal] = useState('')
    const [openCartModal,setOpenCartmodal] = useState(false)
    const [userInfo,setUserInfo] = useState([])

    // terms and Condition
    const [openTerms,setOpenTerms] = useState(false)

    const [checkTerms, setCheckTerms ] = useState(false)
    const [checkPrivacy, setCheckPrivacy ] = useState(false)

    // success creation of account
    const [createSuccess,setCreateSuccess] = useState(false)
    const [successContent,setSuccessContent] = useState('')

    const [allproductName,setAllProductName] = useState(null)
    


    // side nav mobile
    const openSidebar = () => {
      setSideBar(true)
    }
    const closeSidenav = () => {
      setSideBar(false)
    }


    // login modal
    const openLoginmodal = () => {
      setopenLogModal('login')
      setSideBar(false)
    }
    const openCreatemodal = () => {
      setopenLogModal('create')
      setSideBar(false)
    }
    const closeLogModal = () => {
      setopenLogModal('')
    }

    // cart modal
    const openCartmodal = () => {
      setOpenCartmodal(true)
    }
    const closeCartmodal = () => {
      setOpenCartmodal(false)
    }


    const viewAllprd = () => {
      setViewallprod(viewAllPrd => !viewAllPrd)
    }


    // participant form
    const closeParticipantForm = () => {
      setOpenParticipantForm(false)
    }

    // set nav wide
    const widenNav = () => {
      setWiden(widen => !widen)
    }

    // log out user
    const logoutUser = async () => {
      const authToken = Cookies.get('authToken');
      
      if (!currentUser && !userInfo) {
        console.log('no user found');
        return;
      }
      
      
      try {
        axios
          .post(`${apiBaseURL}/logout`, 
            { username: userInfo[0].user_username }, // Send loginUsername in the request body
            {
              headers: {
                'Authorization': `Bearer ${authToken}` // Send the token in the headers
              }
            })
          .then((response) => {
            if (response.data.success === true) {
              // Clear cookies
              Cookies.remove('authToken');
              Cookies.remove('loginSessionToken');
              Cookies.remove('fb_access_Token')

              setTimeout(() => {
                window.location.href = '/'
              }, 100);
            }
          })
          .catch((error) =>{
            console.log(error);
            
          })
    
      } catch (error) {
        console.error('Error in logout:', error.response ? error.response.data.error : error.message);
      }
    };    
    
  return (
    <div className="nav">
      <FetchUser setCurrentUser={setCurrentUser} setUserInfo={setUserInfo} openLogModal={openLogModal} setopenLogModal={setopenLogModal}/>
      <FetchProducts apiBaseURL={apiBaseURL} setAllProductName={setAllProductName}/>
        <nav>
          <div className={`sccssCrtAcc ${createSuccess}`}>
            <SuccessCreateAcc createSuccess={createSuccess} setCreateSuccess={setCreateSuccess} successContent={successContent}/>
          </div>
          <div className={`register-participant-modal ${openParticipanForm}`}>
              <RegisterParticipant setopenLogModal={setopenLogModal} openParticipanForm={openParticipanForm} setOpenParticipantForm={setOpenParticipantForm}/>
              <div className="close-prModal" onClick={closeParticipantForm}></div>
          </div>
          {openTerms &&(
            <div className={`navMdl-terms ${openTerms}`}>
              <TermsModal setOpenTerms={setOpenTerms} setCheckTerms={setCheckTerms} setCheckPrivacy={setCheckPrivacy}/>
            </div>
          )}
          <div className="mainNav">
            <section id='lgSec'>
                <div className="navLogo">
                    <a href="/">
                      <img src={logoCyan} alt="" />
                      <p>Canada</p>
                    </a>
                </div>
            </section>
            <section className='pgSec'>  
              <ul className={`prdList ${widen}`}>
                <IoMdArrowDroprightCircle className={`showprd-list ${widen}`} onClick={widenNav}/>
                <li id='allprdNav' 
                  onMouseEnter={() => setIsHovered(true)}
                >
                  <a href="/all-products"><h2><AiFillProduct id='mnNavIcon'/>Products</h2></a></li>
                
                <li><a href="/porecare"><h2><GiHeartDrop id='mnNavIcon'/>Porecare</h2></a></li>
                <li><a href="/vitaminC"><h2><FaEyeDropper id='mnNavIcon'/>Vitamin C Line</h2></a></li>
                <li><a href="/"><h2><BiSolidShoppingBags id='mnNavIcon'/>Marketplace</h2></a></li>
                <li><a href="/glow-together"><h2><BsHearts id='mnNavIcon'/>Glow Together</h2></a></li>
                <li><a href="/"><h2><FaNewspaper id='mnNavIcon'/>News & Community</h2></a></li>
              </ul>
            </section>
            <section id='scSec'>
              <ul>
                {currentUser && userInfo ? 
                  <li id='loginUser'>
                      <div className="lgUser-img">
                        <a href={`/${userInfo[0].user_username}`}><img src={userInfo[0].user_profile_pic ? `https://2wave.io/skinmiso/customer_profile_picture/${userInfo[0].user_profile_pic}` : defaultProfile} alt="" /></a>
                      </div>
                      <div className="scNavuser">
                        <p onClick={openCartmodal}><MdShoppingCart/></p>
                        <p onClick={logoutUser}><HiOutlineLogout/></p>
                      </div>
                  </li> :
                  <li id='navnoUser'>
                    <span onClick={openLoginmodal}><FaUserAltSlash id='userIcon'/>Login</span>
                  </li>
                }
              </ul>
            </section>
            <section id='mbRSec'>
              <div className="navBar">
                <MdShoppingCart id='mbcartIcon' onClick={openCartmodal}/>
                <FaUser  id='mbuserIcon' onClick={openLoginmodal}/>
                <FaBars id='barIcon' onClick={openSidebar}/>
              </div>
            </section>
          </div>
          {allproductName &&(
            <div 
              className="allPrdNav-container"
              style={{
                top: isHovered ? '3.5vw' : '-10vw',
                opacity: isHovered ? 1 : 0,
                visibility: isHovered ? 'visible' : 'hidden'
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="allprdNav">
                <div className="allproducts">
                  <div className="allprdNames">
                    <section>
                      <h1><BsBrightnessAltHigh id='prdTitleIcon'/>Brightening<FaQuestionCircle id='abtPrdlist'/></h1>
                      <span>
                        {allproductName
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sm_product_name.localeCompare(b.sm_product_name))
                        .filter(prd => prd.sm_product_category === "Vitamin C") // Sort alphabetically
                        .map((prds, i) => (
                          <a href={`/product/${prds.sm_product_name.replace(/\s+/g, '-')}`} key={i}><h2>{prds.sm_product_name}</h2></a>
                        ))}
                      </span>
                    </section>
                    <section>
                      <h1><MdAutoFixHigh id='prdTitleIcon'/>Pore Tightening<FaQuestionCircle id='abtPrdlist'/></h1>
                      <span>
                        {allproductName
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sm_product_name.localeCompare(b.sm_product_name))
                        .filter(prd => prd.sm_product_category === "Pore") // Sort alphabetically
                        .map((prds, i) => (
                          <a href={`/product/${prds.sm_product_name.replace(/\s+/g, '-')}`} key={i}><h2>{prds.sm_product_name}</h2></a>
                        ))}
                      </span>
                    </section>
                    <section>
                      <h1><PiHeartbeat id='prdTitleIcon'/>Soothing<FaQuestionCircle id='abtPrdlist'/></h1>
                      <span>
                        {allproductName
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sm_product_name.localeCompare(b.sm_product_name))
                        .filter(prd => prd.sm_product_category === "Centella") // Sort alphabetically
                        .map((prds, i) => (
                          <a href={`/product/${prds.sm_product_name.replace(/\s+/g, '-')}`} key={i}><h2>{prds.sm_product_name}</h2></a>
                        ))}
                      </span>
                    </section>
                    <section>
                      <h1><RiHandSanitizerLine id='prdTitleIcon'/>Cleansing<FaQuestionCircle id='abtPrdlist'/></h1>
                      <span>
                        {allproductName
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sm_product_name.localeCompare(b.sm_product_name))
                        .filter(prd => prd.sm_product_category === "Other") // Sort alphabetically
                        .map((prds, i) => (
                          <a href={`/product/${prds.sm_product_name.replace(/\s+/g, '-')}`} key={i}><h2>{prds.sm_product_name}</h2></a>
                        ))}
                      </span>
                    </section>
                  </div>
                  {/* <h3>See a Glow like never before, Try it today!🩵</h3> */}
                </div>
                {/* <div className="nav-bndl-img">
                  <a href="/porecare">
                    <img src={poreCarebundle} alt="" />
                  </a>
                </div>
                <div className="nav-bndl-img">
                  <a href="vitaminc">
                    <img src={vitamincbundle} alt="" />
                  </a>
                </div> */}
              </div>
            </div>
          )}
          <div className={`mb-navCartmodal ${openCartModal}`}>
            <CartModal openCartModal={openCartModal}/>
            <div className="clsCartmdl" onClick={closeCartmodal}></div>
          </div>
          <div className={`mb-navLoginmodalNav ${openLogModal}`}>
            {!currentUser &&(<LoginModal setFlowerLoader={setFlowerLoader} setSuccessContent={setSuccessContent} openLogModal={openLogModal} setopenLogModal={setopenLogModal} setUserInfo={setUserInfo} setCreateSuccess={setCreateSuccess} apiBaseURL={apiBaseURL} checkTerms={checkTerms} checkPrivacy={checkPrivacy} setOpenTerms={setOpenTerms}/>)}
            <div className="mbnavLogClose" onClick={closeLogModal}></div>
          </div>
          <div className={`mb-navContainer ${sideBar}`}>
            <div className={`mb-navContents ${sideBar}`}>
              {currentUser && userInfo &&(
                <div className="mb-sideNavAcc">
                  <div className="mb-sdnvUserimg">
                    <img src={sampleImguser} alt="" />
                  </div>
                  <div className="mb-sdnvUserDets">
                    <h1>{userInfo[0].user_username}</h1>
                    <hr />
                    <p><IoSettings id='sdnvSetIcon'/> Account</p>
                  </div>
                </div>
              )}
              {!currentUser &&(
                <div className="mb-sdnavLogin">
                  <div className="mb-sdNlgError">
                    <h1>Welcome to Skinmiso</h1>
                    <p>Start your beauty journey now</p>
                  </div>
                  <div className="sdnavlgBtns">
                    <button onClick={openLoginmodal}>Login</button>
                    <button onClick={openCreatemodal}>Sign up</button>
                  </div>
                </div>
              )}
              <div className="mb-navLists">
                <ul>
                  <hr />
                  <Link to={'/'}><li onClick={closeSidenav}><FaHome id='sideNavIcon'/>Home</li></Link>
                  <li onClick={viewAllprd}><FaShoppingBag id='sideNavIcon'/>All Products <IoIosArrowForward className={`sideArrowIcon ${viewAllPrd}`}/></li>
                  {allproductName &&(
                    <div className={`sdnvAllprd ${viewAllPrd}`}>
                      {allproductName
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sm_product_name.localeCompare(b.sm_product_name)) // Sort alphabetically
                        .map((prod, index) => (
                          <p key={index}>
                            <a href={`/${encodeURIComponent(prod.sm_product_name.replace(/\s+/g, '-'))}`} onClick={closeSidenav}>
                              {prod.sm_product_name}
                            </a>
                          </p>
                      ))}
                    </div>
                  )}
                  <Link to={'/porecare'}><li onClick={closeSidenav}><RiInkBottleLine id='sideNavIcon'/>Porecare</li></Link>
                  <Link to={'/vitaminC'}><li onClick={closeSidenav}><FaEyeDropper id='sideNavIcon'/>Vitamin C Line</li></Link>
                  {/* <li><FaShop id='sideNavIcon'/>Marketplace</li> */}
                  <Link to={'/glow-together'}><li><GiGlowingHands id='sideNavIcon'/>Glow Together</li></Link>
                  {/* <li><FaNewspaper id='sideNavIcon'/>News & Community</li> */}
                  {currentUser &&(
                    <>
                      <hr />
                      <li onClick={logoutUser} id='mbLogout'><IoLogInOutline id='sideNavIcon'/>Logout</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="clsOutmb" onClick={closeSidenav}></div>
          </div>
        </nav>
    </div>
  )
}

export default Nav
