import React,{useState,useEffect} from 'react'
// css
import '../css/all-product.css'
// icons
import { FaStar, FaStarHalf } from "react-icons/fa";
// axios
import axios from 'axios';
// assets
import shortBannner from '../assets/imgs/Promo.png'
import FetchProducts from './datafetch/fetchProduct';

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const Allproducts = () => {
    const [allProducts,setAllproducts] = useState()
    const [allproductName,setAllProductName] = useState()


  return (
    <div className="all-products">
        <FetchProducts apiBaseURL={apiBaseURL} setAllProductName={setAllProductName} setAllproducts={setAllproducts}/>
        <div className="allPrd-Container">
            <div className="allPrd-Header">
                <div className="allprdMain-banner">
                    <img src={shortBannner} alt="" />
                </div>
            </div>
            <div className="allPrd-Contents">
                <ul>
                    {allProducts &&(
                        <>
                            {allProducts.map((prd, i) => (
                                <li key={i}>
                                    <div className="all-prd-Cntents">
                                        <div className="prdImgContent">
                                            <img src={`https://2wave.io/skinmiso/${prd.sm_product_img1}`} alt="" id='prdMainImg'/>
                                            <img src={`https://2wave.io/skinmiso/${prd.sm_product_img_main}`} alt="" id='prdSubImg'/>
                                        </div>
                                        <div className="all-prd-info">
                                            <p>{prd.sm_product_long_description}</p>
                                            <hr />
                                            <div className="allprd-main-info">
                                                <h1>{prd.sm_product_name}</h1>
                                                <div className="prhStrrer">
                                                    <FaStar id='prhStr'/>
                                                    <FaStar id='prhStr'/>
                                                    <FaStar id='prhStr'/>
                                                    <FaStar id='prhStr'/>
                                                    <FaStarHalf id='prhStr'/>
                                                </div>
                                                <h2>(4.5)</h2>
                                            </div>
                                            <h3>${prd.sm_product_price}</h3>
                                            <span>5,542 reviews</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </>
                    )}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Allproducts