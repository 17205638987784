import React,{useState,useEffect} from 'react'
import axios from 'axios'

const FetchProducts = ({apiBaseURL,setAllProductName,setAllproducts}) => {

    useEffect(() => {
        fetchPrds()
        allprds()
    }, [])
    

    const fetchPrds = async () => {
        axios
            .get(`${apiBaseURL}/products`)
            .then((response) =>{
                setAllProductName(response.data)
            })
    } 
    
    const allprds = async () => {
        axios
            .post(`${apiBaseURL}/all-products`)
            .then((response) => {
                
                setAllproducts(response.data.productData)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return null;
}

export default FetchProducts
