import React, { useState,useEffect } from 'react';
// css
import '../css/landing.css'
// axios
import axios from 'axios';
// icons
import { FaRegComment,FaRegHeart, FaStar, FaStarHalf, FaCanadianMapleLeaf, FaBalanceScale,FaHeart,FaHandHoldingHeart,FaMoneyBillWave, FaUsers } from "react-icons/fa";
import { FaQuoteLeft, FaQuoteRight,FaShieldHeart,FaTag } from "react-icons/fa6";
import { AiOutlineGlobal } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { IoCart,IoHappyOutline } from "react-icons/io5";
import { TbArrowsMinimize, TbDroplets } from "react-icons/tb";
import { LuDroplets,LuSendHorizonal } from "react-icons/lu";
import { MdOutlineLightMode, MdGrain,MdCleanHands } from "react-icons/md";
import { RiDiscountPercentFill,RiVerifiedBadgeFill } from "react-icons/ri";
import { IoMdAddCircle,IoIosArrowUp } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
// assets
import webIntro from '../assets/vids/SkinmisoCommercial.mp4'
import glowVid from '../assets/vids/smile.mp4'
import cards from '../assets/imgs/cardGIf.gif'
import rcfoamBack from '../assets/imgs/ricefoamBack.png'
import riceFoam from '../assets/imgs/foam_tube.png'
import user from '../assets/imgs/user.jpg'
import poreBundle from '../assets/imgs/poreBundle.png'
import coreset from '../assets/imgs/poreCorsetLand.jpg'
import porePurifying from '../assets/imgs/porePurifyingLand.jpg'
import vitcCream from '../assets/imgs/vitCLand.jpg'
import vitToner from '../assets/imgs/pureVitSerum.jpg'
import riceFoampromo from '../assets/imgs/riceFoamLnd.jpg'
import lndPost from '../assets/imgs/lndPostsamp.jpg'
// js file
import FetchUser from './datafetch/fetchUser';



const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const Landing = ({mainflowerLoader}) => {
  
  const [likePost, setLikePost] = useState(false);
  const [likeComment, setLikeComment] = useState(false);

  const [likedComments, setLikedComments] = useState([]);
  const [likedReplies, setLikedReply] = useState([]);
  const [likeReply, setLikeReply] = useState(false);
  const [postjson, setPostjson] = useState()

  const [addComment,setAddComment] = useState('')

  const [userInfo,setUserInfo] = useState(null)
  const [currentUser,setCurrentUser] = useState(null)

  const likepost = (i) => {
    setLikePost(likePost => !likePost);
    
    const like = {
      postIndex: i,
      isLike: likePost
    }
    
    axios
      .patch(`${apiBaseURL}/update-like`, like,
        { headers: {
          'Content-Type': 'application/json',
        }})
      .then((response) => {
        console.log(response);
        fetchPost()
      })
  };

  const likecomment = (i,j) => {
    setLikeComment(likeComment => !likeComment);
    setLikedComments((prevLikedComments) => {
      // Check if the comment is already liked
      const isAlreadyLiked = prevLikedComments.includes(j);

      // Toggle the liked state
      if (isAlreadyLiked) {
        return prevLikedComments.filter((index) => index !== j);
      } else {
        return [...prevLikedComments, j];
      }
    });
    const like = {
      postIndex: i,
      commentIndex: j,
      isLike: likeComment
    }
    console.log(like);
    
    axios
      .patch(`${apiBaseURL}/update-comment-like`, like,
        { headers: {
          'Content-Type': 'application/json',
        }})
      .then((response) => {
        console.log(response);
        fetchPost()
      })
      .catch((error) => {
        console.log(error);
        
      })
  };

  const likereply = (i,j,k) => {
    setLikeReply(likeReply => !likeReply);
    const replyIdentifier = `${j}-${k}`;

    setLikedReply((prevLikedReplies) => {
      // Check if this reply is already liked
      const isAlreadyLiked = prevLikedReplies.includes(replyIdentifier);

      // Toggle the liked state of the reply
      if (isAlreadyLiked) {
        return prevLikedReplies.filter((id) => id !== replyIdentifier);
      } else {
        return [...prevLikedReplies, replyIdentifier];
      }
    });
    const like = {
      postIndex: i,
      commentIndex: j,
      isLike: likeReply,
      replyIndex: k
    }
    
    axios
      .patch(`${apiBaseURL}/update-reply-like`, like,
        { headers: {
          'Content-Type': 'application/json',
        }})
      .then((response) => {
        console.log(response);
        fetchPost()
      })
  };

  const addcomment = (postIndex) => {
    const commentData = {
      postIndex : postIndex, 
    }
    
  }


  useEffect(() => {
    fetchPost()

  }, [])
  
  const fetchPost = () => {
    axios
      .post(`${apiBaseURL}/land-post`)
      .then((response) => {
        console.log(response.data);
        
        setPostjson(response.data)
      })
      .catch((error) => {
        console.log(error);
        
      })
  }

  return (
    <div>
      <FetchUser setUserInfo={setUserInfo} setCurrentUser={setCurrentUser}/>
      <div className="landing">
        <div className="mainLanding">
          <div className="landing-container">
            <div className="landing-header">
              <div className={`lheadName ${mainflowerLoader}`}>
                {/* <FaCanadianMapleLeaf id='canadianMaple1'/>
                <FaCanadianMapleLeaf id='canadianMaple2'/> */}
                <h1>Skinmiso</h1>
                <h2>canada</h2>
                <p>Join the global skincare revolution with SKINMISO, the ultimate name in Korean beauty.</p>
                <a href={'/all-products'}><button>Shop now</button></a>
              </div>
              <div className="lheader-video">
                <div className="lheader-content">
                  <video autoPlay muted loop>
                    <source src={webIntro} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
            <div className="landing-contents">
              <section>
                <div className="lndCards-container">
                  <div className="lndCards-content">
                    <div className="headCard">
                      <h1>Experience the World’s Best Korean Skincare</h1>
                      <p>Renowned for our pore-specialized formulas and innovative solutions, SKINMISO offers you the very best in skincare. Discover why millions trust SKINMISO for clear, radiant skin, and try it for yourself. Your journey to flawless skin starts here</p>
                    </div>
                    <div className="landing-cards">
                      <div className="lndCard">
                        <span>
                          <div className="crdrightImg">
                            <img src={coreset} alt="" />
                          </div>
                          <div className="crdrightImg-container">
                            <div className="crdrightImg-head">
                              <ul>
                                <li><div className="lndTooltp"><h2>Oil Control</h2></div><LuDroplets/></li>
                                <li><div className="lndTooltp"><h2>Balancing Moisture</h2></div><FaBalanceScale/></li>
                                <li><div className="lndTooltp"><h2>Tightening Pores</h2></div><TbArrowsMinimize/></li>
                              </ul>
                              <ul>
                                <li><IoCart/></li>
                              </ul>
                            </div>
                            <div className="crdrightImg-content">
                              <h1>Pore Coreset Serum</h1>
                              <hr />
                              <p>Achieve visibly tighter and refined pores with SKINMISO's Pore Corset Serum. This lightweight, fast-absorbing formula delivers immediate pore-tightening results while balancing oil production and hydrating the skin. Perfect for all skin types.</p>
                            </div>
                          </div>
                        </span>
                        <span>
                          <div className="crdrightImg">
                            <img src={porePurifying} alt="" />
                          </div>
                          <div className="crdrightImg-container">
                            <div className="crdrightImg-head">
                              <ul>
                                <li><div className="lndTooltp"><h2>Oil Control</h2></div><LuDroplets/></li>
                                <li><div className="lndTooltp"><h2>Balancing Moisture</h2></div><FaBalanceScale/></li>
                                <li><div className="lndTooltp"><h2>Clear up face</h2></div><TbDroplets/></li>
                              </ul>
                              <ul>
                                <li><IoCart/></li>
                              </ul>
                            </div>
                            <div className="crdrightImg-content">
                              <h1>Pore Purifying Toner</h1>
                              <hr />
                              <p>The SKINMISO Pore Purifying Toner is your essential step for clearer, more refined skin. Designed to deeply cleanse and balance, this toner works to purify pores and remove excess oil without stripping the skin’s natural moisture</p>
                            </div>
                          </div>
                        </span>
                        <span>
                          <div className="crdrightImg">
                            <img src={vitcCream} alt="" />
                          </div>
                          <div className="crdrightImg-container">
                            <div className="crdrightImg-head">
                              <ul>
                                <li><div className="lndTooltp"><h2>Hydrates</h2></div><LuDroplets/></li>
                                <li><div className="lndTooltp"><h2>Brighten Up</h2></div><MdOutlineLightMode/></li>
                                <li><div className="lndTooltp"><h2>Tightening Pores</h2></div><TbArrowsMinimize/></li>
                              </ul>
                              <ul>
                                <li><IoCart/></li>
                              </ul>
                            </div>
                            <div className="crdrightImg-content">
                              <h1>Pure Vitamin C Cream</h1>
                              <hr />
                              <p>Discover the secret to luminous, youthful skin with SKINMISO Pure Vitamin C Cream. This advanced cream is crafted with a high concentration of pure Vitamin C to brighten your complexion, diminish dark spots, and reduce the appearance of fine lines.</p>
                            </div>
                          </div>
                        </span>
                        <span>
                          <div className="crdrightImg">
                            <img src={vitToner} alt="" />
                          </div>
                          <div className="crdrightImg-container">
                            <div className="crdrightImg-head">
                              <ul>
                                <li><div className="lndTooltp"><h2>Hydrates</h2></div><LuDroplets/></li>
                                <li><div className="lndTooltp"><h2>Balancing Moisture</h2></div><FaBalanceScale/></li>
                                <li><div className="lndTooltp"><h2>Tightening Pores</h2></div><TbArrowsMinimize/></li>
                              </ul>
                              <ul>
                                <li><IoCart/></li>
                              </ul>
                            </div>
                            <div className="crdrightImg-content">
                              <h1>Puure Vitamin C Serum</h1>
                              <hr />
                              <p>Vitamin C Serum is packed with powerful antioxidants that brighten and even out skin tone, while reducing the appearance of dark spots and blemishes. Infused with Vitamin C and Hyaluronic Acid, it boosts collagen production, hydrates deeply, and helps achieve a radiant, youthful glow.</p>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="crdSliderbtn">
                      <a href={'/all-products'}><button>View All</button></a>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="lndPromo">
                  <div className="lndPromo-container">
                    <div className="lndPromo-header">
                      <p><RiDiscountPercentFill id='prmIcon'/> Skinmiso Promo</p>
                      <h1>BUY 2 GET 1</h1>
                      <h2>Rice Foam Cleansing</h2>
                    </div>
                    <div className="lndPromo-contents">
                      <ul>
                        <li>
                          <span id='rcFoamLnd'>
                            <MdGrain id='lndGrain'/>
                            <div className="rcFoamImg">
                              <img src={riceFoampromo} alt="" />
                              <IoIosArrowUp id='upGrainIcon'/>
                            </div>
                            <div className="rcFoamImg-content">
                              <foam>
                                <p><MdCleanHands id='rcFoamIcn'/> Deep Cleansing</p>
                                <p><MdOutlineLightMode id='rcFoamIcn'/> Brightening Effect</p>
                                <p><LuDroplets id='rcFoamIcn'/> Hydration Boost</p>
                                <p><FaHandHoldingHeart id='rcFoamIcn'/> Gentle on Skin</p>
                                <p><FaShieldHeart id='rcFoamIcn'/> Pore Care</p>
                              </foam>
                              <foaminfo>
                                <h1>Rice Foam Cleansing is a gentle, yet powerful cleanser enriched with rice extract to deeply cleanse, brighten, and nourish your skin. It effectively removes impurities and excess oil without stripping moisture, leaving your complexion refreshed, smooth, and radiant. Perfect for daily use, it helps refine skin texture and cares for your pores, delivering a clean, hydrated, and glowing finish.</h1>
                                <div className="fmdata-btns">
                                  <button><FaTag id='fmDataIcon'/> View Product</button>
                                  <button><FaMoneyBillWave id='fmDataIcon'/> Buy Now</button>
                                </div>
                              </foaminfo>
                            </div>
                          </span>
                        </li>
                        <li>
                          {postjson &&(
                            <span id='lndUsers'>
                              {postjson.map((post,i) => (
                                <>
                                  <div className="lndReccom">
                                    <div className="lndReccom-prf">
                                      <div className="ldnRecomImg">
                                        <img src={user} alt="" />
                                      </div>
                                      <IoMdAddCircle id='followUser'/>
                                      <div className='ldnRecomUsername'>
                                        <h1>Heredity Sleiv <RiVerifiedBadgeFill id='lndVerified'/></h1>
                                        <p><AiOutlineGlobal id='glbIcon'/> Public | Nov 13 2024</p>
                                      </div>
                                    </div>
                                    <p id='pstCaption'>{post.post.caption}</p>
                                    <div className="lndPostimg">
                                      <img src={lndPost} alt="" />
                                    </div>
                                    <div className="lndPostRects">
                                      <ul>
                                        <li onClick={() => likepost(i)}>{likePost? <FaHeart className={`lndcomUsrIcons ${likePost}`}/> : <FaRegHeart className={`lndcomUsrIcons ${likePost}`}/>}</li>
                                        <li><FaRegComment/></li>
                                      </ul>
                                      <p>{post.post.likeCount} {post.post.likeCount > 0 ? "Likes" : ""}</p>
                                    </div>
                                  </div>
                                  <div className="lndReccomCom">
                                    <div className="lnd-add-comment">
                                      <div id="cmentEmoji">
                                        <IoHappyOutline/>
                                      </div>
                                      <input type="text" placeholder='write something...'/>
                                      <button><LuSendHorizonal/></button>
                                    </div>
                                    <div className="lndReccomComments">
                                        <ul>
                                          {post.post.comments.map((comment, commentIndex) =>(
                                            <li key={commentIndex}>
                                              <div className="lnd-commnts">
                                                <div className="lnd-usr-comImg">
                                                  <img src={user} alt="" />
                                                </div>
                                                <div className="lnd-cmmts-nme">
                                                  <h1>{comment.name}</h1>
                                                  <p>{comment.date}</p>
                                                </div>
                                                <BsThreeDotsVertical id='lnd-cm-dt'/>
                                              </div>
                                              <div className="lnd-usr-commnt">
                                                <p>{comment.comment}</p>
                                              </div>
                                              <div className="lnd-usr-btns">
                                                <button
                                                  onClick={() => likecomment(i, commentIndex)}
                                                >
                                                  {likedComments.includes(commentIndex) ? (
                                                    <FaHeart className="lndcomUsrIcons true" />
                                                  ) : (
                                                    <FaRegHeart className="lndcomUsrIcons" />
                                                  )}
                                                  {comment.likeCount > 0 ? comment.likeCount : ''} {comment.likeCount > 0 ? 'Likes' : ''}
                                                </button>
                                                <button>
                                                  <FaRegComment className="lndcomUsrIcons" />
                                                  {comment.replies.length} {comment.replies.length > 0 ? 'Replies' : 'Reply'}
                                                </button>
                                              </div>
                                              {comment.replies.map((replies,replyIndex) => (
                                                <div className="lnd-usr-rpls" key={replyIndex}>
                                                  <div className="lnd-commnts">
                                                    <div className="lnd-usr-comImg">
                                                      <img src={user} alt="" />
                                                    </div>
                                                    <div className="lnd-cmmts-nme">
                                                      <h1>{replies.name}</h1>
                                                      <p>{replies.date}</p>
                                                    </div>
                                                    <BsThreeDotsVertical id='lnd-cm-dt'/>
                                                  </div>
                                                  <div className="lnd-usr-commnt">
                                                    <p>{replies.reply}</p>
                                                  </div>
                                                  <div className="lnd-usr-btns">
                                                    <button
                                                      onClick={() => likereply(i, commentIndex,replyIndex)}
                                                      >
                                                      {likedReplies.includes(`${commentIndex}-${replyIndex}`) ? (
                                                          <FaHeart className="lndcomUsrIcons true" />
                                                        ) : (
                                                          <FaRegHeart className="lndcomUsrIcons" />
                                                        )}
                                                        {replies.likeCount > 0 ? replies.likeCount : ''} {replies.likeCount > 0 ? 'Likes' : ''}
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </li>
                                          ))}
                                        </ul>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </span>
                          )}
                          <span id='lndFollow'>
                            <ul>
                              <li id='lndtop'>
                                <span>
                                  <h1>Top</h1>
                                  <p>Talents</p>
                                </span>
                              </li>
                              <li>
                                <div className="lndfl-img">
                                  <img src={user} alt="" />
                                </div>
                                <div className="lndfl-info">
                                  <div className="fllw-cnt">
                                    <div>
                                      <h1>Heredity Sleiv <RiVerifiedBadgeFill id='lndVerified'/></h1>
                                      <p id='flw-tl'>All time favorite</p>
                                    </div>
                                    <span>
                                      <p><FaHeart id='flwnIcon'/> 10k</p>
                                      <p><FaUsers id='flwnIcon'/> 10k</p>
                                    </span>
                                  </div>
                                  <div className="lndfl-btns">
                                    <button>View Talent</button>
                                    <button>Follow</button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="lndfl-img">
                                  <img src={user} alt="" />
                                </div>
                                <div className="lndfl-info">
                                  <div className="fllw-cnt">
                                    <div>
                                      <h1>Heredity Sleiv <RiVerifiedBadgeFill id='lndVerified'/></h1>
                                      <p id='flw-tl'>All time favorite</p>
                                    </div>
                                    <span>
                                      <p><FaHeart id='flwnIcon'/> 10k</p>
                                      <p><FaUsers id='flwnIcon'/> 10k</p>
                                    </span>
                                  </div>
                                  <div className="lndfl-btns">
                                    <button>View Talent</button>
                                    <button>Follow</button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </span>
                          <span>
                            <div className="rcfoamBanner">
                              <img src="" alt="" />
                            </div>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              {/* <section>
                <div className="lndBundle">
                  <div className="prBundle">
                    <div className="prBundle">
                      <h1>Pore Perfection with the SKINMISO Pore Bundle!</h1>
                    </div>
                    <div className="prBundleContent">
                      <div className="prBundleHead">
                        <h1>Get Rice foam for Free!</h1>
                        <p>Buy Pore Purifying Toner, Pore zero-night cream & Pore Coreset and Get Skinmiso Rice Foam Cleansing for free</p>
                      </div>
                      <div className="prBundleImg">
                        <img src={poreBundle} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
              {/* <section>
                <div className="lndStRev">
                  <div className="lndStRevHeader">
                    <h1>Glow Stories & Reviews</h1>
                  </div>
                  <div className="lndRvContents">
                    <ul>
                      <li>
                        <div className="ldnRvSstars">
                          <span>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                          </span>
                        </div>
                        <div className="ldnRvSsContainer">
                          <div className="ldnRvSImg">
                            <span>
                              <img src={user} alt="" />
                            </span>
                          </div>
                          <div className="ldnRvSsContent">
                            <FaQuoteLeft id='lndQtr'/>
                            <h1>Mary</h1>
                            <p>I've struggled with enlarged pores for years, but the SKINMISO Pore Corset Serum is a game-changer! After just a few weeks, my pores look visibly smaller</p>
                            <FaQuoteRight id='lndQtl'/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="ldnRvSstars">
                          <span>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                          </span>
                        </div>
                        <div className="ldnRvSsContainer">
                          <div className="ldnRvSImg">
                            <span>
                              <img src={user} alt="" />
                            </span>
                          </div>
                          <div className="ldnRvSsContent">
                            <FaQuoteLeft id='lndQtr'/>
                            <h1>Mary</h1>
                            <p>I've struggled with enlarged pores for years, but the SKINMISO Pore Corset Serum is a game-changer! After just a few weeks, my pores look visibly smaller</p>
                            <FaQuoteRight id='lndQtl'/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="ldnRvSstars">
                          <span>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                          </span>
                        </div>
                        <div className="ldnRvSsContainer">
                          <div className="ldnRvSImg">
                            <span>
                              <img src={user} alt="" />
                            </span>
                          </div>
                          <div className="ldnRvSsContent">
                            <FaQuoteLeft id='lndQtr'/>
                            <h1>Mary</h1>
                            <p>I've struggled with enlarged pores for years, but the SKINMISO Pore Corset Serum is a game-changer! After just a few weeks, my pores look visibly smaller</p>
                            <FaQuoteRight id='lndQtl'/>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </section> */}
              {/* <section>
                <div className="lndingLstSec">
                  <div className="lndnglstSecR">
                    <h1>Glow Together</h1>
                    <p><span>At SKINMISO, we believe that every dream deserves a chance to shine.</span> That's why we're launching the "Glow Together" campaign – an initiative dedicated to discovering and nurturing aspiring talents. Whether you're a dancer, singer, artist, or athlete, SKINMISO is here to support your journey to success.</p>
                  </div>
                  <div className="lndinglstSecL">
                    <video autoPlay muted loop>
                      <source src={glowVid} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </section> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
