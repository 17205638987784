import React,{ useState,useEffect,useRef } from 'react'
// css
import '../../css/admin.css'
// axios
import axios from 'axios';
// cookies
import Cookies from 'js-cookie'
// icons
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { FaUsers, FaRegHandshake, FaBoxOpen, FaEye, FaCheck, FaCrown  } from "react-icons/fa";
import { FaClipboardUser, FaFilter } from "react-icons/fa6";
import { PiUsersThreeFill } from "react-icons/pi";
import { LuSearch } from "react-icons/lu";
import { IoCashOutline, IoSettings, IoTicketSharp, IoGlobe  } from "react-icons/io5";
import { IoIosNotifications, IoIosWarning } from "react-icons/io";
import { BsClipboard2DataFill, BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineNetworkCheck } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { HiMiniReceiptPercent } from "react-icons/hi2";

// assets
import map from '../../assets/imgs/mind-map.png'
import RegisterCounter from '../chart/registerCounter';
import samplePrd from '../../assets/imgs/foam_tube.png'
import sampUser from '../../assets/imgs/user.jpg'
import noData from '../../assets/imgs/empty-folder.png'
import UserPreferenceChart from '../chart/userprefChart';

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

const Admin = () => {
    const [prdname, setPrdname] = useState(null);
    const [prdtype, setPrdtype] = useState(null);
    const [prdprice, setPrdprice] = useState(null);
    const [prdSize, setPrdsize] = useState(null);
    const [prdsupply, setPrdsupply] = useState(null);
    const [prdbrand, setPrdbrand] = useState(null);
    const [prdshortDescription, setPrdshortDescription] = useState(null);
    const [prdlongDescription, setPrdlongtDescription] = useState(null);
    const [prdShortHeader, setPrdShortHeader] = useState(null);
    const [prdShortInfo, setPrdShortInfo] = useState(null);
    const [prdTags, setPrdTags] = useState([]);
    const [inputTag, setInputTag] = useState(''); 
    const [prdBenefitsHeader, setPrdBenefitsHeader] = useState(null);
    const [prdBenefits, setPrdBenefits] = useState([]);
    const [inputPrdBenefits, setInputPrdBenefits] = useState(''); 
  
    const [prdMainVid, setPrdMainVid] = useState(null);
  
    const [prdShortlinks, setPrdShortLinks] = useState([{ shortsNumber: 1, link: '' }]);
    const [prdIngredients, setprdIngredients] = useState([{ ingredient: '', info: '' }]);

    const [activePage, setActivePage] = useState("overview")
    const [activeUsersort, setActiveUsersort] = useState("Date")
    const [sortUsersdata,setSortUsersdata] = useState(false)
    const [searchUser,setSearchUser] = useState("")

    const [productNames,setProductNames] = useState(null)
    // user tab
    const [users,setUsers] = useState(null)
    const [initialusers,setInitialusers] = useState(null)
    const [newUsers, setNewUsers] = useState(null)
    const [activeUsers, setActiveUsers] = useState(null)

    const [pendingParticipant, setPendingParticipant] = useState(null)
    const [approvedParticipant, setApprovedParticipant] = useState(null)
    const [newPendingParticipant, setNewPendingParticipant] = useState(null)

    const [trafficData, setTrafficData] = useState(null)
    
    const divRef = useRef(null); 

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
  
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return date.toLocaleString('en-US', options);
    };

    const currentDate = () => {
      const date = new Date();
  
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return date.toLocaleString('en-US', options);
    }

    // useEffect
    useEffect(() => {
      // fetch functions
      getUsers()
      getParticipants()
      getWebtraffic()
      getProducts()
    }, [!users])
    
    // fetch datas

    const getUsers = () => {
      const authToken = Cookies.get('authToken');  
      const region = { region: "Philippines" }
      axios
        .post(`${apiBaseURL}/admin/customer-list`, region, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        })
        .then((res) => {
          const data = res.data
          const userdata = data.users
          if (data.success) {
            setInitialusers(userdata.sort((a,b) => new Date(b.user_register_time) - new Date(a.user_register_time)))
            setUsers(userdata.sort((a,b) => new Date(b.user_register_time) - new Date(a.user_register_time)))
            setNewUsers(data.users.filter(user=> formatDate(user.user_register_time) === currentDate()))
            setActiveUsers(data.users.filter(user => user.user_activity === 'active'))
          } else {
            console.log(data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const getParticipants = () => {
      const authToken = Cookies.get('authToken');  
      const region = { region: "Philippines" }
      axios
        .post(`${apiBaseURL}/admin/participants-list`, region ,{
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        })
        .then((res) => {
          const data = res.data
          setPendingParticipant(data.users.filter(participant => participant.user_participant_approved === "Pending"))
          setApprovedParticipant(data.users.filter(participant => participant.user_participant_approved === "approved"))
          setNewPendingParticipant(data.users.filter(user=> formatDate(user.user_participant_form_application_date) === currentDate()))
        })
    }

    const getWebtraffic = () => {
      const authToken = Cookies.get('authToken');  
      axios
        .get(`${apiBaseURL}/admin/web-traffic` ,{
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        })
        .then((res) => {
          const data = res.data;
          setTrafficData(data.data)
        })
    }

    const getProducts = () => {
      try {
        axios.get(`${apiBaseURL}/products`)
        .then((res) => {
          setProductNames(res.data)
        })
      } catch (error) {
        
      }
    }

    // open pages tab btns
    const openOverview = () => {
      setActivePage("overview")
    }
    const openAnalytics = () => {
      setActivePage("analytics")
    }
    const openUsers = () => {
      setActivePage("users")
    }
    const openPartners = () => {
      setActivePage("partners")
    }
    const openPromos = () => {
      setActivePage("promos")
    }
    const openProducts = () => {
      setActivePage("products")
    }
    const openTickets = () => {
      setActivePage("tickets")
    }
    const openSettings = () => {
      setActivePage("settings")
    }

    // sorting and filtering

    // sorting btns
    const openuserSorting = () => {
      setSortUsersdata(sortUsersdata => !sortUsersdata)
    }
    // Function to scroll the div back to the top
    const scrollToTop = () => {
      if (divRef.current) {
        divRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    const sortUsers = (data,sortype) => {
      
      if (sortype === "time") {
        const sortedUsers = [...data].sort((a, b) => {
          if (b["user_register_time"] < a["user_register_time"]) return -1;
          if (a["user_register_time"] > b["user_register_time"]) return 1;
          return 0;
        });
        setUsers(sortedUsers)
        setActiveUsersort("Date")
      }
      if (sortype === "username") {
        const sortedUsers = [...data].sort((a, b) => {
          if (a["user_username"] < b["user_username"]) return -1;
          if (a["user_username"] > b["user_username"]) return 1;
          return 0;
        });
        setUsers(sortedUsers)
        setActiveUsersort("Username")
      }
      if (sortype === "role") {
        const sortedUsers = [...data].sort((a, b) => {
          if (a["user_role"] < b["user_role"]) return -1;
          if (a["user_role"] > b["user_role"]) return 1;
          return 0;
        });
        setUsers(sortedUsers)
        setActiveUsersort("Role")
      }
      if (sortype === "activity") {
        const sortedUsers = [...data].sort((a, b) => {
          if (a["user_activity"] < b["user_activity"]) return -1;
          if (a["user_activity"] > b["user_activity"]) return 1;
          return 0;
        });
        setUsers(sortedUsers)
        setActiveUsersort("Activity")
      }
      setSortUsersdata("")
      scrollToTop()
    }

    const searchuser = (e) => {
      e.preventDefault();
      if (searchUser) {
        const filterUsername = users.filter(user=> user.user_username.toLowerCase().includes(searchUser.toLowerCase()));
        if (filterUsername.length > 0) {
          setUsers(filterUsername)
          return;
        }
      }
      setUsers(initialusers)
      return;
    }
    // For handling file inputs
    const [prdImages, setPrdImages] = useState({
      productImg: null,
      productImg1: null,
      productImg2: null,
      productImg3: null,
      productImg4: null,
      benefitsImg: null,
      ingredientsImg: null,
      banner: null,
    });
  
    // Handle file selection
    const handleFileChange = (e, type) => {
      setPrdImages({
        ...prdImages,
        [type]: e.target.files[0],
      });
    };
  
    // Function to add a tag
    const handleAddTag = (e) => {
      if (e.key === 'Enter' && inputTag.trim() !== '') {
        setPrdTags([...prdTags, inputTag.trim()]); // Add tag to array
        setInputTag(''); // Clear the input field
      }
    };

    
    const handleAddBenefits = (e) => {
        if (e.key === 'Enter' && inputPrdBenefits.trim() !== '') {
          setPrdBenefits([...prdBenefits, inputPrdBenefits.trim()]); // Add tag to array
          setInputPrdBenefits(''); // Clear the input field
        }
      };

    // Function to update the short link
    const handleShortlinkChange = (index, value) => {
      const updatedShortlinks = prdShortlinks.map((short, i) => {
        if (i === index) {
          return { ...short, link: value };
        }
        return short;
      });
      setPrdShortLinks(updatedShortlinks);
    };
  
    // Function to add a new short link
    const handleAddShort = () => {
      setPrdShortLinks([
        ...prdShortlinks,
        { shortsNumber: prdShortlinks.length + 1, link: '' }, // Add new short link
      ]);
    };


    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedIngredients = [...prdIngredients];
        updatedIngredients[index][name] = value;
        setprdIngredients(updatedIngredients);
    };

    const handleAddIngredient = () => {
        setprdIngredients([...prdIngredients, { ingredient: '', info: '' }]);
    };
  
    // Submit function to gather all data and send a POST request
    const handleSubmit = async () => {
      const formData = new FormData();
      
      formData.append('productName', prdname);
      formData.append('productType', prdtype);
      formData.append('productPrice', prdprice);
      formData.append('productSize', prdSize);
      formData.append('productSupply', prdsupply);
      formData.append('productBrand', prdbrand);
      formData.append('productShortDescription', prdshortDescription);
      formData.append('productLongDescription', prdlongDescription);
      formData.append('productMainVideo', prdMainVid);
      formData.append('prdShortHead', prdShortHeader);
      formData.append('prdShortInfo', prdShortInfo);
      
      // Append tags
      formData.append('prdtags', prdTags)
      formData.append('prdBenefitsHead', prdBenefitsHeader)
      formData.append('prdBenefits', prdBenefits)
  
      // Append images
      Object.keys(prdImages).forEach((key) => {
        if (prdImages[key]) {
          formData.append(key, prdImages[key]);
        }
      });
  
      // Append steps
      formData.append('prdIngredients', JSON.stringify(prdIngredients));
  
      // Append short links
      prdShortlinks.forEach((short, index) => formData.append(`shortLinks[${index}]`, short.link));
  
      // for (let [key, value] of formData.entries()) {
      //   console.log(key, value);
      // }
      
      // Make the POST request
      try {
        const response = await axios.post(
            "http://localhost/skinmiso/product/insert_database.php",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        );
        console.log(response);
        
      } catch (error) {
        console.error('Error submitting product:', error);
      }
    };

    
  return (
    <div className="admin">
      <div className="main-admin">
        <div className="admin-container">
          <div className="admin-header">
            <div className="admin-head">
              <a href="/">
                <h1>Skinmiso <br /><span>Canada</span></h1>
              </a>
              <ul>
                <li className={`pnelbtn-overview ${activePage}`} onClick={openOverview}><BsClipboard2DataFill id='admHeadIcons'/> Overview</li>
                <li className={`pnelbtn-analytics ${activePage}`} onClick={openAnalytics}><TbDeviceDesktopAnalytics id='admHeadIcons'/> Analytics</li>
                <li className={`pnelbtn-users ${activePage}`} onClick={openUsers}><FaUsers id='admHeadIcons'/> Users</li>
                <li className={`pnelbtn-partners ${activePage}`} onClick={openPartners}><FaRegHandshake id='admHeadIcons'/> Partners</li>
                <li className={`pnelbtn-promos ${activePage}`} onClick={openPromos}><HiMiniReceiptPercent id='admHeadIcons'/> Promos</li>
                <li className={`pnelbtn-products ${activePage}`} onClick={openProducts}><AiFillProduct id='admHeadIcons'/> Products</li>
                <li className={`pnelbtn-tickets ${activePage}`} onClick={openTickets}><IoTicketSharp id='admHeadIcons'/> Tickets</li>
                <li className={`pnelbtn-settings ${activePage}`} onClick={openSettings}><IoSettings id='admHeadIcons'/> Settings</li>
              </ul>
            </div>
          </div>
          <div className="admin-contents">
            {activePage === "overview" &&
              <section>
              <div className="adm-cntents-head">
                <h1><BsClipboard2DataFill id='admHeadIcon'/> Data Overview</h1>
                <p>Welcome to the Skinmiso Canada Admin, this is for managing and overseeing all system data. This section provides a comprehensive view of key metrics, records, and activities, enabling you to monitor performance, review details, and make informed decisions. Utilize these tools to ensure data accuracy, streamline operations, and maintain optimal efficiency across all administrative functions.</p>
              </div>
              <div className="adm-cntnts-dta">
              {users && 
                  <ul>
                    <li>
                      <FaUsers id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1> {users?.length ? users.length : "?"}</h1>
                      <p>
                        Total Customers
                        {newUsers?.length > 0 ? <h2>{newUsers.length} new</h2> : ""}
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                    <li>
                      <FaClipboardUser id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1> {pendingParticipant ? pendingParticipant.length : ""}</h1>
                      <p>
                        Pending Registered
                        {newPendingParticipant?.length > 0 ? <h2>{newPendingParticipant.length} new</h2> : ""}
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                    <li>
                      <FaRegHandshake id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1> {approvedParticipant ? approvedParticipant.length : ""}</h1>
                      <p>
                        Total Partners
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                  <li>
                    <FaBoxOpen id='admcDataIcon'/>
                    <BsThreeDotsVertical id='admViewIcon'/>
                    <h1> {productNames ? productNames.length : "" }</h1>
                    <p>
                      Total Products
                    </p>
                    <img src={map} alt="" id='imgIcnbg'/>
                  </li>
                  <li>
                    <IoCashOutline id='admcDataIcon'/>
                    <BsThreeDotsVertical id='admViewIcon'/>
                    <h1> 51k</h1>
                    <p>
                      Monthly Sales
                      <h2>5 new</h2>
                    </p>
                    <img src={map} alt="" id='imgIcnbg'/>
                  </li>
                </ul>
              }
                <div className="admn-cntnt-mtrics">
                  <div className="adcnt-mtrs-head">
                    <h1><IoIosNotifications id='admHeadIcon'/> Updates</h1>
                    <p> <FaFilter id='admfltIcon'/> Filter by: <span>Realtime</span></p>
                  </div>
                  <div className="adcnt-mtrs-contents">
                    <div className="ovrv-sysRep">
                      <h1><IoIosWarning style={{ marginRight: ".2vw"}}/> Crash Reports</h1>
                      <ul>
                        <li>
                          <div>
                            <FaUsers id="adcntMtrcIcon"/>
                            <span>
                              <h1>Customer Report</h1>
                              <p>Login System Failure</p>
                            </span>
                          </div>
                          <div>
                            <FaEye id='rptIcn'/>
                            <FaCheck  id='rptIcn'/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <FaUsers id="adcntMtrcIcon"/>
                            <span>
                              <h1>Customer Report</h1>
                              <p>Login System Failure</p>
                            </span>
                          </div>
                          <div>
                            <FaEye id='rptIcn'/>
                            <FaCheck  id='rptIcn'/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <FaUsers id="adcntMtrcIcon"/>
                            <span>
                              <h1>Customer Report</h1>
                              <p>Login System Failure</p>
                            </span>
                          </div>
                          <div>
                            <FaEye id='rptIcn'/>
                            <FaCheck  id='rptIcn'/>
                          </div>
                        </li>
                      </ul>
                      <div className="ovr-sysRep-btns">
                        <button><FaUsers style={{ marginRight: ".2vw"}}/> Customer</button>
                        <button><IoSettings style={{ marginRight: ".2vw"}}/> System</button>
                        <button><MdOutlineNetworkCheck style={{ marginRight: ".2vw"}}/> Network</button>
                      </div>
                    </div>
                    <div className="ovrv-RegCount">
                      <RegisterCounter/>
                    </div>
                  </div>
                </div>
                <div className="ovr-usrs">
                  <div className="ovr-usrs-cnts">
                    <div className="ovr-prds">
                      <h1 style={{ color: "#4fc4d3", fontSize: "1vw"}}>Product Data</h1>
                      <p style={{ color: "gray", fontSize: ".65vw"}}>Summarize Overview of Product Data</p>
                      <ul>
                        <li>
                          <h1>32k</h1>
                          <h3>Rice Foam</h3>
                          <p>Most Ordered Product</p>
                          <img src={samplePrd} alt="" />
                        </li>
                        <li>
                          <h1>32k</h1>
                          <h3>Rice Foam</h3>
                          <p>Most Add to Cart Product</p>
                          <img src={samplePrd} alt="" />
                        </li>
                        <li>
                          <h1>32k</h1>
                          <h3>Rice Foam</h3>
                          <p>Trending Product</p>
                          <img src={samplePrd} alt="" />
                        </li>
                      </ul>
                      <ul id='ovr-prd-smcard'>
                        <li>
                          <h1>32k</h1>
                          <span>
                            <h3>Rice Foam</h3>
                            <p>Most Visited Product</p>
                          </span>
                        </li>
                        <li>
                          <h1>32k</h1>
                          <span>
                            <h3>Rice Foam</h3>
                            <p>Most Visited Product</p>
                          </span>
                        </li>
                        <li>
                          <h1>32k</h1>
                          <span>
                            <h3>Rice Foam</h3>
                            <p>Most Visited Product</p>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="ovr-prtcpnt">
                      <h1 style={{ color: "#4fc4d3", fontSize: "1vw"}}>Partners Data</h1>
                      <p style={{ color: "gray", fontSize: ".65vw"}}>Summarize Overview of Partners Data</p>
                      <section>
                        <ul>
                          <li>
                            <div className="ovr-prt-img">
                              <img src={sampUser} alt="" />
                            </div>
                            <span>
                              <p>Best Seller</p>
                              <h1>Hayley Williams</h1>
                              <h2><FaCrown style={{ marginRight: ".2vw"}}/> Rank 1</h2>
                            </span>
                          </li>
                          <li>
                            <div className="ovr-prt-img">
                              <img src={sampUser} alt="" />
                            </div>
                            <span>
                              <p>Best Seller</p>
                              <h1>Hayley Williams</h1>
                              <h2><FaCrown style={{ marginRight: ".2vw"}}/> Rank 2</h2>
                            </span>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <div className="ovr-prt-img">
                              <img src={sampUser} alt="" />
                            </div>
                            <span>
                              <p>Best Seller</p>
                              <h1>Hayley Williams</h1>
                              <h2><FaCrown style={{ marginRight: ".2vw"}}/> Rank 3</h2>
                            </span>
                          </li>
                          <li>
                            <div className="ovr-prt-img">
                              <img src={sampUser} alt="" />
                            </div>
                            <span>
                              <p>Best Seller</p>
                              <h1>Hayley Williams</h1>
                              <h2><FaCrown style={{ marginRight: ".2vw"}}/> Rank 4</h2>
                            </span>
                          </li>
                        </ul>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              </section>
            }
            {activePage === "analytics" &&
              <section>
              <div className="adm-cntents-head">
                <h1><BsClipboard2DataFill id='admHeadIcon'/> Data Overview</h1>
                <p>Welcome to the Skinmiso Canada Admin, this is for managing and overseeing all system data. This section provides a comprehensive view of key metrics, records, and activities, enabling you to monitor performance, review details, and make informed decisions. Utilize these tools to ensure data accuracy, streamline operations, and maintain optimal efficiency across all administrative functions.</p>
              </div>
              <div className="adm-cntnts-dta">
              </div>
              </section>
            }
            {activePage === "users" &&
              <section>
              <div className="adm-cntents-head">
                <h1><FaUsers id='admHeadIcon'/> Users Data</h1>
                <p>Access comprehensive user data, including insights into active users, newly registered accounts, and other key metrics.</p>
              </div>
              <div className="adm-cntnts-dta">
                {users && 
                  <ul>
                    <li>
                      <FaUsers id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1> {users?.length ? users.length : "?"}</h1>
                      <p>
                        Total Customers
                        {newUsers?.length > 0 ? <h2>{newUsers.length} new</h2> : ""}
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                    <li>
                      <FaClipboardUser id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1> {pendingParticipant ? pendingParticipant.length : ""}</h1>
                      <p>
                        Pending Registered
                        {newPendingParticipant?.length > 0 ? <h2>{newPendingParticipant.length} new</h2> : ""}
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                    <li>
                      <FaRegHandshake id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1> {approvedParticipant ? approvedParticipant.length : ""}</h1>
                      <p>
                        Total Partners
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                    <li>
                      <PiUsersThreeFill id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1>{activeUsers ? activeUsers.length : ""}</h1>
                      <p>
                        Active Users
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                    <li>
                      <IoGlobe id='admcDataIcon'/>
                      <BsThreeDotsVertical id='admViewIcon'/>
                      <h1> {trafficData ? trafficData.length : ""}</h1>
                      <p>
                        Web traffic
                      </p>
                      <img src={map} alt="" id='imgIcnbg'/>
                    </li>
                  </ul>
                }
                <div className="admn-cntnt-mtrics">
                  <div className="adcnt-mtrs-head">
                    <h1><IoIosNotifications id='admHeadIcon'/> Customers Update</h1>
                    <div className='adcnt-mtrc-srtfltr'>
                      <form onSubmit={searchuser}>
                        <div className="src-adcnt-user">
                          <input type="text" value={searchUser} onChange={(e) => {setSearchUser(e.target.value)}} placeholder='Search Customer'/> <button type='submit'><LuSearch style={{ marginRight: ".1vw"}}/> Search</button>
                        </div>
                      </form>
                      <p> <FaFilter id='admfltIcon'/> Sort by: <span onClick={openuserSorting}>{activeUsersort}</span></p>
                    </div>
                    <div className={`adcnt-mtrs-flter-hd ${sortUsersdata}`}>
                      <h2 onClick={() => sortUsers(users,"time")}>Date</h2>
                      <h2 onClick={() => sortUsers(users,"username")}>Username</h2>
                      <h2 onClick={() => sortUsers(users,"role")}>Role</h2>
                      <h2 onClick={() => sortUsers(users,"activity")}>Activity</h2>
                    </div>
                  </div>
                  <div className="adcnt-mtrs-contents">
                    <div className="adcnt-mtrs-users" ref={divRef}>
                      <table>
                        <thead>
                          <tr>
                            <th>Customer Username</th>
                            <th>Customer Email</th>
                            <th>Customer Mobile Number</th>
                            <th>User Role</th>
                            <th>Customer Activity</th>
                            <th>Account Registration Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users ?
                            <>
                              {users
                              .slice()
                              .map((user, i) => {
                                const isNewUser = currentDate() === formatDate(user.user_register_time); 
                                return (
                                  <>
                                    <tr key={i} id={isNewUser ? "new-user-row" : ""}>
                                      <td>{user.user_username}</td>
                                      <td>{user.user_email}</td>
                                      <td>{user.user_mobileno}</td>
                                      <td>{user.user_role}</td>
                                      <td>{user.user_activity}</td>
                                      <td>{formatDate(user.user_register_time)}</td>
                                    </tr>
                                  </>
                                );
                              })}
                            </> : 
                            <>
                              <div className="adm-nodta-rt">
                                <span>
                                  <div className="adm-ndt-img">
                                    <img src={noData} alt="" />
                                  </div>
                                  <h1>no data retrieve</h1>
                                </span>
                              </div>
                            </>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="adm-usr-prf">
                  <h1>User Preference</h1>
                  <div className="adm-usr-mst-pck">
                    <p>Comprehensive Chart for user most pick Item</p>
                    <div className="adm-usr-prf-chrt">
                      <UserPreferenceChart/>
                    </div>
                  </div>
                </div>
              </div>
              </section>
            }
            {activePage === "partners" &&
              <section>
              <div className="adm-cntents-head">
                <h1><BsClipboard2DataFill id='admHeadIcon'/> Data Overview</h1>
                <p>Welcome to the Skinmiso Canada Admin, this is for managing and overseeing all system data. This section provides a comprehensive view of key metrics, records, and activities, enabling you to monitor performance, review details, and make informed decisions. Utilize these tools to ensure data accuracy, streamline operations, and maintain optimal efficiency across all administrative functions.</p>
              </div>
              <div className="adm-cntnts-dta">
              </div>
              </section>
            }
            {activePage === "promos" &&
              <section>
             
              </section>
            }
            {activePage === "products" &&
              <section>
              </section>
            }
            {activePage === "tickets" &&
              <section>
              </section>
            }
            {activePage === "settings" &&
              <section>
              </section>
            }
            {/* <p>Product Name</p>
            <input type="text" value={prdname} onChange={(e) => setPrdname(e.target.value)} />

            <p>Product Type</p>
            <input type="text" value={prdtype} onChange={(e) => setPrdtype(e.target.value)} />

            <p>Product Size</p>
            <input type="text" value={prdSize} onChange={(e) => setPrdsize(e.target.value)} />

            <p>Product Price</p>
            <input type="number" value={prdprice} onChange={(e) => setPrdprice(e.target.value)} />

            <p>Product Supply</p>
            <input type="number" value={prdsupply} onChange={(e) => setPrdsupply(e.target.value)} />

            <p>Product Brand</p>
            <input type="text" value={prdbrand} onChange={(e) => setPrdbrand(e.target.value)} />

            <p>Product Long Description</p>
            <input type="text" value={prdlongDescription} onChange={(e) => setPrdlongtDescription(e.target.value)} />

            <p>Product Short Description</p>
            <input type="text" value={prdshortDescription} onChange={(e) => setPrdshortDescription(e.target.value)} />

            <p>Product Tags</p>
            {prdTags.map((tag, i) => (
              <div key={i}>{tag}</div>
            ))}
            <input
              type="text"
              value={inputTag}
              onChange={(e) => setInputTag(e.target.value)}
              onKeyDown={handleAddTag}
              placeholder="Enter a tag and press Enter"
            />
            
            <p>Product benefits Headers</p>
            <input type="text" value={prdBenefitsHeader} onChange={(e) => setPrdBenefitsHeader(e.target.value)} />

            <p>Product benefits</p>
            {prdBenefits.map((benefits, i) => (
              <div key={i}>{benefits}</div>
            ))}
            <input
              type="text"
              value={inputPrdBenefits}
              onChange={(e) => setInputPrdBenefits(e.target.value)}
              onKeyDown={handleAddBenefits}
              placeholder="Enter a benefits and press Enter"
            />

            <p>Product Main Video</p>
            <input type="text" value={prdMainVid} onChange={(e) => setPrdMainVid(e.target.value)} />

            <p>Product Images</p>
            <p>main img</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg')} />
            <p>product img 1</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg1')} />
            <p>product img 2</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg2')} />
            <p>product img 3</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg3')} />
            <p>product img 4</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'productImg4')} />
            <p>benefits img</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'benefitsImg')} />
            <p>ingredients img</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'ingredientsImg')} />
            <p>banner</p>
            <input type="file" onChange={(e) => handleFileChange(e, 'banner')} />

            <p>Product Short Links</p>
            {prdShortlinks.map((short, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <label>Short {short.shortsNumber}:</label>
                <input
                  type="text"
                  value={short.link}
                  onChange={(e) => handleShortlinkChange(index, e.target.value)}
                  placeholder={`Enter short link ${short.shortsNumber}`}
                />
              </div>
            ))}
            <button onClick={handleAddShort}>Add Short Link</button>

            <p>Input ingredients</p>
            {prdIngredients.map((prd, index) => (
                <div key={index}>
                <p>Ingredient</p>
                <input
                    type="text"
                    name="ingredient"
                    value={prd.ingredient}
                    onChange={(e) => handleInputChange(index, e)}
                />
                <p>Ingredient Info</p>
                <input
                    type="text"
                    name="info"
                    value={prd.info}
                    onChange={(e) => handleInputChange(index, e)}
                />
                </div>
            ))}
            <button onClick={handleAddIngredient}>Add Ingredient</button>

            <p>Product Shorts header</p>
            <input type="text" value={prdShortHeader} onChange={(e) => setPrdShortHeader(e.target.value)} />
            <p>Product Shorts info</p>
            <input type="text" value={prdShortInfo} onChange={(e) => setPrdShortInfo(e.target.value)} />
            <button onClick={handleSubmit}>Submit Product</button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin