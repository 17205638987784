import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

const FetchUser = ({ setCurrentUser, setUserInfo , setopenLogModal, openLogModal, setInitialData,setCustomerAddress }) => {

    useEffect(() => {
        fetchcurrentUser();
    }, []);

    const fetchcurrentUser = async () => {
        const loginCookie = Cookies.get('loginSessionToken');
        const authCookie = Cookies.get('authToken');
        
        if (loginCookie && authCookie) {
            const userAuth = { userToken: authCookie };
            
            try {
                const response = await axios.post(`${apiBaseURL}/user`, userAuth, {
                    headers: { 'Authorization': `Bearer ${authCookie}` }
                });
                
                if (response.data.data.length > 0) {
                    setCurrentUser(response.data.data[0].user_customerID);
                    const userData = response.data.data
                    
                    setUserInfo(userData);
                    setInitialData(JSON.stringify(userData))
                    
                    
                    const customerFetchAddress = {
                        houseNumber : userData[0].user_houseNo,
                        street : userData[0].user_street,
                        city : userData[0].user_city,
                        district : userData[0].user_district,
                        region : userData[0].user_region,
                        state : userData[0].user_state,
                        postalCode : userData[0].user_postal_code,
                        country : userData[0].user_country,
                        addressLabel : userData[0].user_address_label
                    }
                    
                    setCustomerAddress(customerFetchAddress)

                }
            } catch (error) {
                console.log(error);
                
                if (error.response?.data?.message === 'login expired') {
                    Cookies.remove('authToken');
                    Cookies.remove('loginSessionToken');
                }
            }
        } else if (openLogModal === '') {
            setTimeout(() => {
                setopenLogModal('login');
            }, 5000);
        }
    };

    return null;
};

export default FetchUser;
