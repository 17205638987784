import React, { useState, useEffect } from 'react';
import { PolarArea } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
);

const UserPreferenceChart = () => {
  const [fontSize, setFontSize] = useState(window.innerWidth);
    
  useEffect(() => {
    const handleResize = () => {
      setFontSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data = {
    labels: ['Registration', 'Application', 'Approval'],
    datasets: [
      {
        label: 'User Activities',
        data: [300, 200, 100], // Sample data
        backgroundColor: [
          'rgba(75, 192, 192, 0.5)',
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow dynamic height/width
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: fontSize * 0.0065, // Font size based on viewport width
          },
        },
      },
      tooltip: {
        enabled: false, // Disable default tooltip
        external: (context) => {
          // Create a custom tooltip element
          const tooltipModel = context.tooltip;
          let tooltipEl = document.getElementById('chartjs-tooltip');
  
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.zIndex = '9999';
            document.body.appendChild(tooltipEl);
          }
  
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }
  
          // Set styles for glassmorphism
          tooltipEl.style.background = 'rgba(255, 255, 255, 0.7)';
          tooltipEl.style.backdropFilter = 'blur(10px)';
          tooltipEl.style.border = '1px solid rgba(255, 255, 255, 0.3)';
          tooltipEl.style.borderRadius = '10px';
          tooltipEl.style.padding = '10px';
          tooltipEl.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
          tooltipEl.style.color = 'black';
          tooltipEl.style.opacity = '1';
          tooltipEl.style.transition = 'opacity 0.3s ease';
  
          // Set content
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map((b) => b.lines);
  
            let innerHtml = '<div>';
            titleLines.forEach((title) => {
              innerHtml += `<div style="font-weight: bold; margin-bottom: 5px;">${title}</div>`;
            });
  
            bodyLines.forEach((body) => {
              innerHtml += `<div>${body}</div>`;
            });
            innerHtml += '</div>';
  
            tooltipEl.innerHTML = innerHtml;
          }
  
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        },
      },
    },
  };

  return <PolarArea data={data} options={options} />;
};

export default UserPreferenceChart;
